import { KindeOrg } from "@inrange/building-manager-api-client/models-organisation";
import { Loading } from "@inrange/theme-components";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { ReactNode, createContext, useEffect, useState } from "react";
import { hotjar } from "react-hotjar";

interface User {
  name: string;
  email: string;
  family_name: string;
  given_name: string;
  picture: string;
  kinde_org_code: string;
  org_id?: string;
  kinde_orgs: KindeOrg[];
}

interface UserContextValue {
  user?: User;
  logout?: () => void;
  onOrgSwitch?: (id: string) => void;
}

const UserContext = createContext<UserContextValue>({});

const UserProvider = ({ children }: { children: ReactNode }) => {
  const {
    login,
    logout,
    isLoading: isAuthLoading,
    user: kindeUser,
    getClaim: getKindeTokenClaim,
  } = useKindeAuth();
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    if (kindeUser && getKindeTokenClaim) {
      const orgIdClaim = getKindeTokenClaim("external_org_id");
      const orgId = orgIdClaim ? (orgIdClaim.value as string) : undefined;
      const kindeOrgCode = getKindeTokenClaim("org_code")!.value as string;
      const kindeOrgs = getKindeTokenClaim("organizations", "id_token")!
        .value as KindeOrg[];
      setUser({
        name: `${kindeUser.given_name} ${kindeUser.family_name}`,
        email: kindeUser.email!,
        family_name: kindeUser.family_name!,
        given_name: kindeUser.given_name!,
        picture: kindeUser.picture!,
        kinde_org_code: kindeOrgCode,
        org_id: orgId,
        kinde_orgs: kindeOrgs,
      });
      const emailDomain = kindeUser?.email?.split("@")[1];
      if (import.meta.env.PROD && emailDomain !== "inrange.io") {
        if (!hotjar.initialized()) {
          hotjar.initialize(3032509, 6);
        }
      }
    }
  }, [kindeUser, getKindeTokenClaim]);

  if (!isAuthLoading && !kindeUser) {
    login({
      app_state: {
        redirectTo: window.location.pathname + window.location.search,
      },
    });
  }

  if (!user) {
    return <Loading label="Loading ..." />;
  }

  const onOrgSwitch = (id: string) => {
    const thisOrg = id === user.kinde_org_code;
    if (thisOrg) return;
    login({
      org_code: id,
      app_state: {
        redirectTo: window.location.pathname + window.location.search,
      },
    });
  };

  return (
    <UserContext.Provider value={{ user, logout, onOrgSwitch }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
