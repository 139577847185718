import React from "react";
import {
  ColumnContainer,
  ColumnTextStyle,
  DateHeader,
  DescriptionHeader,
  InvoiceDueDateHeader,
  InvoiceStatementAmountHeader,
  InvoiceStatementNumberHeader,
  IssueDateHeader,
} from "./FileListHeaderComponents";
import { HeaderComponentProps, TableHeaderCell } from "./TableHeaderCell";

interface FilesListHeaderProps {
  isBilling: boolean;
  handleColumnClick: (columnName: string) => void;
  sortColumn: string;
  sortAscending: boolean;
}

interface HeaderData {
  columnName: string;
  sortValue: string;
  headerComponent: React.FC<HeaderComponentProps>;
}

const FilesListHeader: React.FC<FilesListHeaderProps> = ({
  isBilling,
  handleColumnClick,
  sortColumn,
  sortAscending,
}) => {
  const headerData: HeaderData[] = [
    {
      columnName: "description",
      sortValue: "description",
      headerComponent: DescriptionHeader,
    },
  ];

  if (isBilling) {
    headerData.push(
      {
        columnName: "number",
        sortValue: "number",
        headerComponent: InvoiceStatementNumberHeader,
      },
      {
        columnName: "amount",
        sortValue: "amount",
        headerComponent: InvoiceStatementAmountHeader,
      },
      {
        columnName: "issueDate",
        sortValue: "issueDate",
        headerComponent: IssueDateHeader,
      },
      {
        columnName: "invoiceDueDate",
        sortValue: "invoiceDueDate",
        headerComponent: InvoiceDueDateHeader,
      }
    );
  } else {
    headerData.push({
      columnName: "date",
      sortValue: "date",
      headerComponent: DateHeader,
    });
  }

  return (
    <tr>
      {headerData.map((header) => (
        <TableHeaderCell
          key={header.columnName}
          handleColumnClick={handleColumnClick}
          columnName={header.columnName}
          sortColumn={sortColumn}
          sortAscending={sortAscending}
          sortValue={header.sortValue}
          HeaderComponent={header.headerComponent}
        />
      ))}
      <th></th>
    </tr>
  );
};

export default FilesListHeader;

interface HeaderProps {
  label: string;
}

export const Header: React.FC<HeaderProps> = ({ label }) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>{label}</ColumnTextStyle>
    </ColumnContainer>
  );
};
