import React from "react";
import styled from "styled-components";
import formatCurrency from "../../formatting/formatCurrency";

interface ProjectCostsProps {
  hardwareCost: number;
  modelName: string;
  installationCost: number;
  gridConnectionCost: number;
  currencyCode: string;
}

interface ProjectCostProps {
  label: string;
  value: string;
}

export const ProjectCosts: React.FC<ProjectCostsProps> = ({
  hardwareCost,
  modelName,
  installationCost,
  gridConnectionCost,
  currencyCode,
}) => {
  return (
    <ProjectCostList>
      <ProjectCost
        label="Hardware"
        value={formatCurrency(hardwareCost, currencyCode)}
      />
      <ProjectCost
        label="Installation"
        value={formatCurrency(installationCost, currencyCode)}
      />
      <ProjectCost
        label="Grid Connection"
        value={
          modelName === "License"
            ? formatCurrency(gridConnectionCost, currencyCode)
            : formatCurrency(0, currencyCode)
        }
      />
    </ProjectCostList>
  );
};

const ProjectCostList = styled.ul`
  margin: 0 8px;
  padding: 0;
  list-style: none;
`;

const ProjectCost: React.FC<ProjectCostProps> = ({ label, value }) => {
  return (
    <ProjectCostListItem>
      <ProjectCostListItemLabel>{label}</ProjectCostListItemLabel>
      <ProjectCostListItemValue>{value}</ProjectCostListItemValue>
    </ProjectCostListItem>
  );
};

const ProjectCostListItem = styled.li`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding: 0 8px;
`;

const ProjectCostListItemLabel = styled.p``;

const ProjectCostListItemValue = styled.p`
  text-align: right;
`;
