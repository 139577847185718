import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import { calcBehindTheMeter } from "@inrange/calculations/calcCfeScore";
import { getScoreBySiteID } from "@inrange/theme-components";
import calcIsOwnerOccupied from "../utils/calcIsOwnerOccupied";
import calcSiteOwnershipTypeForOrg from "../utils/calcSiteOwnershipTypeForOrg";
import {
  getBehindMeterCost,
  getLatestActualsMonthIndex,
} from "./sitelist-utils";

// we use these weights to ensure these are sorted before inrange scores (5 is the max inrange score)
const siteStatusWeights: Record<string, number> = {
  contractSigned: 6,
  loaPending: 7,
  gridAppPendingSurveyInProgress: 8,
  gridAppPendingSurveyCompleted: 9,
  constructionPrelimsInProgress: 10,
  installationRoofWorks: 11,
  installationElectrical: 12,
  witnessTestingInProgress: 13,
  waitingOnDnoWitnessTest: 14,
};

export const sortByName = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean
): void => {
  listedSites.sort((a, b) => {
    return sortAscending
      ? a.name.localeCompare(b.name)
      : b.name.localeCompare(a.name);
  });
};

const sortByTenants = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean
): OrgSiteListEntry[] => {
  return listedSites.sort((a, b) => {
    const nameA =
      a.siteOwnerships.find((item) => item.ownership !== "landlord")?.name ||
      "";
    const nameB =
      b.siteOwnerships.find((item) => item.ownership !== "landlord")?.name ||
      "";
    const comparison = nameA.localeCompare(nameB);
    return sortAscending ? comparison : -comparison;
  });
};

export const sortByLandlords = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean
): void => {
  listedSites.sort((a, b) => {
    return sortAscending
      ? a.landlordCount! - b.landlordCount!
      : b.landlordCount! - a.landlordCount!;
  });
};

export const sortByNetUsage = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean
): void => {
  sortByProperty(listedSites, sortAscending, (site) =>
    calcBehindTheMeter(site.energyFlowAnnual)
  );
};

export const sortByProperty = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  propertyAccessor: (site: OrgSiteListEntry) => number
): void => {
  listedSites.sort((a, b) => {
    const propertyA = propertyAccessor(a);
    const propertyB = propertyAccessor(b);
    return sortAscending ? propertyA - propertyB : propertyB - propertyA;
  });
};

export const sortByTwoProperties = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  propertyAccessor1: (site: OrgSiteListEntry) => number,
  propertyAccessor2: (site: OrgSiteListEntry) => number
): void => {
  listedSites.sort((a, b) => {
    const propertyA1 = propertyAccessor1(a);
    const propertyB1 = propertyAccessor1(b);
    if (propertyA1 !== propertyB1) {
      return sortAscending ? propertyA1 - propertyB1 : propertyB1 - propertyA1;
    }
    const propertyA2 = propertyAccessor2(a);
    const propertyB2 = propertyAccessor2(b);
    return sortAscending ? propertyA2 - propertyB2 : propertyB2 - propertyA2;
  });
};

export const sortByIRR = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  organisation: Organisation
): void => {
  sortByProperty(listedSites, sortAscending, (site) => {
    const isOwnerOccupier = calcIsOwnerOccupied(site, organisation);
    return site.financialModels[isOwnerOccupier ? "ownerOccupier" : "landlord"]
      .license.irr!;
  });
};

const EXPORT_LIMIT_SORT_UNAVAILABLE = 1;
const EXPORT_LIMIT_SORT_COMING_SOON = 2;
const EXPORT_LIMIT_SORT_AVAILABLE = 3;

export const getExportLimitSortValue = (site: OrgSiteListEntry): number => {
  return site.exportLimit !== null
    ? site.exportLimit! > 0
      ? EXPORT_LIMIT_SORT_AVAILABLE
      : EXPORT_LIMIT_SORT_UNAVAILABLE
    : !site.substationData.success
      ? EXPORT_LIMIT_SORT_COMING_SOON
      : site.substationData.generationHeadroomKva > 0
        ? EXPORT_LIMIT_SORT_AVAILABLE
        : EXPORT_LIMIT_SORT_UNAVAILABLE;
};

export const sortByNetworkAvailabilityTenant = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean
): void => {
  sortByTwoProperties(
    listedSites,
    sortAscending,
    (site) => getExportLimitSortValue(site),
    (site) => {
      return site.financialModels.tenant.savings || 0;
    }
  );
};

export const sortByNetworkAvailabilityLicense = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  organisation: Organisation
): void => {
  sortByTwoProperties(
    listedSites,
    sortAscending,
    (site) => getExportLimitSortValue(site),
    (site) => {
      const isOwnerOccupier = calcIsOwnerOccupied(site, organisation);
      return isOwnerOccupier
        ? site.financialModels.ownerOccupier.license.irr || 0
        : site.financialModels.landlord.license.irr || 0;
    }
  );
};

export const sortByNetworkAvailabilityLease = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  organisation: Organisation
): void => {
  sortByTwoProperties(
    listedSites,
    sortAscending,
    (site) => getExportLimitSortValue(site),
    (site) => {
      const isOwnerOccupier = calcIsOwnerOccupied(site, organisation);
      return isOwnerOccupier
        ? site.financialModels.ownerOccupier.lease.revenue || 0
        : site.financialModels.landlord.lease.revenue || 0;
    }
  );
};

export const sortBySiteStatusOrScoreTenant = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  organisation: Organisation
): void => {
  sortByTwoProperties(
    listedSites,
    sortAscending,
    (site) => {
      const siteScore = getScoreBySiteID(organisation.id, site.id);
      const validStatus = site.operationalStatus !== "notStarted";
      return validStatus
        ? siteStatusWeights[site.operationalStatus]
        : siteScore || 0;
    },
    (site) => {
      return site.financialModels.tenant.savings;
    }
  );
};

export const sortBySiteStatusOrScoreLicense = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  organisation: Organisation
): void => {
  sortByTwoProperties(
    listedSites,
    sortAscending,
    (site) => {
      const siteScore = getScoreBySiteID(organisation.id, site.id);
      const validStatus = site.operationalStatus !== "notStarted";
      return validStatus
        ? siteStatusWeights[site.operationalStatus]
        : siteScore || 0;
    },
    (site) => {
      const ownership = calcSiteOwnershipTypeForOrg(site, organisation.id);
      return site.financialModels[
        ownership === "ownerOccupier" ? "ownerOccupier" : "landlord"
      ].license.irr!;
    }
  );
};

export const sortBySiteStatusOrScoreLease = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  organisation: Organisation
): void => {
  sortByTwoProperties(
    listedSites,
    sortAscending,
    (site) => {
      const siteScore = getScoreBySiteID(organisation.id, site.id);
      const validStatus = site.operationalStatus !== "notStarted";
      return validStatus
        ? siteStatusWeights[site.operationalStatus]
        : siteScore || 0;
    },
    (site) => {
      const ownership = calcSiteOwnershipTypeForOrg(site, organisation.id);
      return site.financialModels[
        ownership === "ownerOccupier" ? "ownerOccupier" : "landlord"
      ].lease.revenue;
    }
  );
};

export const sortByProjectCost = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean
): void => {
  sortByProperty(listedSites, sortAscending, (site) => site.projectCosts.total);
};

export const sortByLastMonthCost = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean
): void => {
  sortByProperty(listedSites, sortAscending, (site) => {
    const lastMonthIndex = getLatestActualsMonthIndex(site);
    if (lastMonthIndex === undefined) {
      return 0;
    }
    const lastMonthEnergyFlows =
      site.energyFlowMonthlyActuals![lastMonthIndex].energyFlow;
    return getBehindMeterCost(lastMonthEnergyFlows.matchedEnergy);
  });
};

export const sortByLastMonthProcurement = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean
): void => {
  sortByProperty(listedSites, sortAscending, (site) => {
    const lastMonthIndex = getLatestActualsMonthIndex(site);
    if (lastMonthIndex === undefined) {
      return 0;
    }
    const lastMonthEnergyFlows =
      site.energyFlowMonthlyActuals![lastMonthIndex].energyFlow;
    return lastMonthEnergyFlows.procurement;
  });
};
export const sortByLastMonthRevenue = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  organisation: Organisation
): void => {
  sortByProperty(listedSites, sortAscending, (site) => {
    const lastMonthIndex = getLatestActualsMonthIndex(site);
    if (lastMonthIndex === undefined) {
      return 0;
    }
    const lastMonthFinancialModels =
      site.financialModelsMonthlyActuals![lastMonthIndex];
    const siteOwnership = site.siteOwnerships.find(
      (siteOwnership) => siteOwnership.orgID === organisation.id
    );
    const ownershipKey =
      siteOwnership?.ownership === "ownerOccupier"
        ? "ownerOccupier"
        : "landlord";
    return lastMonthFinancialModels[ownershipKey][site.activeInvestmentModel]
      .revenue;
  });
};

export const sortByLastMonthSavings = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  organisation: Organisation
): void => {
  sortByProperty(listedSites, sortAscending, (site) => {
    const lastMonthIndex = getLatestActualsMonthIndex(site);
    if (lastMonthIndex === undefined) {
      return 0;
    }
    const lastMonthFinancialModels =
      site.financialModelsMonthlyActuals![lastMonthIndex];
    const siteOwnership = site.siteOwnerships.find(
      (siteOwnership) => siteOwnership.orgID === organisation.id
    );
    const ownershipKey =
      siteOwnership?.ownership === "ownerOccupier" ? "ownerOccupier" : "tenant";
    return ownershipKey === "ownerOccupier"
      ? lastMonthFinancialModels[ownershipKey][site.activeInvestmentModel]
          .savings
      : lastMonthFinancialModels[ownershipKey].savings;
  });
};

export const sortByLastMonthDemandFulfilled = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean
): void => {
  sortByProperty(listedSites, sortAscending, (site) => {
    const lastMonthIndex = getLatestActualsMonthIndex(site);
    if (lastMonthIndex === undefined) {
      return 0;
    }
    const lastMonthEnergyFlows =
      site.energyFlowMonthlyActuals![lastMonthIndex].energyFlow;
    return lastMonthEnergyFlows.procurement / lastMonthEnergyFlows.demand;
  });
};

export const sortByLastMonthGeneration = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean
): void => {
  sortByProperty(listedSites, sortAscending, (site) => {
    const lastMonthIndex = getLatestActualsMonthIndex(site);
    if (lastMonthIndex === undefined) {
      return 0;
    }
    const lastMonthEnergyFlows =
      site.energyFlowMonthlyActuals![lastMonthIndex].energyFlow;
    return lastMonthEnergyFlows.generation;
  });
};

export const sortByPayback = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  organisation: Organisation
): void => {
  sortByProperty(listedSites, sortAscending, (site) => {
    const isOwnerOccupier = calcIsOwnerOccupied(site, organisation);
    return site.financialModels[isOwnerOccupier ? "ownerOccupier" : "landlord"]
      .license.paybackMonths!;
  });
};

export const sortByLicenseRevenue = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  organisation: Organisation
): void => {
  sortByProperty(listedSites, sortAscending, (site) => {
    const isOwnerOccupier = calcIsOwnerOccupied(site, organisation);
    return isOwnerOccupier
      ? site.financialModels.ownerOccupier.license.revenue
      : site.financialModels.landlord.license.revenue;
  });
};

export const sortByLeaseRevenue = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  organisation: Organisation
): void => {
  sortByProperty(listedSites, sortAscending, (site) => {
    const isOwnerOccupier = calcIsOwnerOccupied(site, organisation);
    return isOwnerOccupier
      ? site.financialModels.ownerOccupier.lease.revenue
      : site.financialModels.landlord.lease.revenue;
  });
};

export const sortByCarbonSavings = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean
): void => {
  sortByProperty(
    listedSites,
    sortAscending,
    (site) => site.calculations.emissionsAvoided.totalAvoidance
  );
};

export const sortByRoofArea = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean
): void => {
  sortByProperty(listedSites, sortAscending, (site) => site.totalBuildingArea);
};

export const sortByleaseRevenuePerArea = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  organisation: Organisation
): void => {
  listedSites.sort((a, b) => {
    const isOwnerOccupierA = calcIsOwnerOccupied(a, organisation);
    const leaseRevenuePerAreaA = isOwnerOccupierA
      ? a.financialModels.ownerOccupier.lease.revenue / a.totalBuildingArea
      : a.financialModels.landlord.lease.revenue / a.totalBuildingArea;

    const isOwnerOccupierB = calcIsOwnerOccupied(b, organisation);
    const leaseRevenuePerAreaB = isOwnerOccupierB
      ? b.financialModels.ownerOccupier.lease.revenue / b.totalBuildingArea
      : b.financialModels.landlord.lease.revenue / b.totalBuildingArea;

    return sortAscending
      ? leaseRevenuePerAreaA - leaseRevenuePerAreaB
      : leaseRevenuePerAreaB - leaseRevenuePerAreaA;
  });
};

export const sortByAnnualSavings = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean,
  organisation: Organisation
): void => {
  sortByProperty(listedSites, sortAscending, (site) => {
    const siteOwnership = site.siteOwnerships.find(
      (siteOwnership) => siteOwnership.orgID === organisation.id
    );
    const ownershipKey =
      siteOwnership?.ownership === "ownerOccupier" ? "ownerOccupier" : "tenant";
    if (ownershipKey === "ownerOccupier") {
      return site.financialModels[ownershipKey][site.activeInvestmentModel]
        .savings;
    } else {
      return site.financialModels[ownershipKey].savings;
    }
  });
};

export const sortByEnergyProcured = (
  listedSites: OrgSiteListEntry[],
  sortAscending: boolean
): void => {
  listedSites.sort((a, b) => {
    return sortAscending
      ? a.energyFlowAnnual.procurement - b.energyFlowAnnual.procurement
      : b.energyFlowAnnual.procurement - a.energyFlowAnnual.procurement;
  });
};

export function sortByColumn(
  listedSites: OrgSiteListEntry[],
  sortColumn: string,
  sortAscending: boolean,
  organisation: Organisation
) {
  const sortFunctions = {
    name: sortByName,
    irr: sortByIRR,
    networkAvailabilityTenant: sortByNetworkAvailabilityTenant,
    networkAvailabilityLicense: sortByNetworkAvailabilityLicense,
    networkAvailabilityLease: sortByNetworkAvailabilityLease,
    projectCost: sortByProjectCost,
    payback: sortByPayback,
    licenseRevenue: sortByLicenseRevenue,
    tenants: sortByTenants,
    carbonSavings: sortByCarbonSavings,
    roofArea: sortByRoofArea,
    leaseRevenue: sortByLeaseRevenue,
    leaseRevenuePerArea: sortByleaseRevenuePerArea,
    annualSavings: sortByAnnualSavings,
    landlords: sortByLandlords,
    netUsage: sortByNetUsage,
    energyProcured: sortByEnergyProcured,
    siteStatusTenant: sortBySiteStatusOrScoreTenant,
    siteStatusLicense: sortBySiteStatusOrScoreLicense,
    siteStatusLease: sortBySiteStatusOrScoreLease,
    lastMonthCost: sortByLastMonthCost,
    lastMonthProcurement: sortByLastMonthProcurement,
    lastMonthRevenue: sortByLastMonthRevenue,
    lastMonthSavings: sortByLastMonthSavings,
    lastMonthDemandFulfilled: sortByLastMonthDemandFulfilled,
    lastMonthGeneration: sortByLastMonthGeneration,
  };

  const sortFunction = sortFunctions[sortColumn];

  if (sortFunction) {
    sortFunction(listedSites, sortAscending, organisation);
  }
}

export const filterSitesByText = (
  sites: OrgSiteListEntry[],
  textFilter: string
) => {
  return sites.filter(
    (site) =>
      site.name.toLowerCase().includes(textFilter.toLowerCase()) ||
      site.address.toLowerCase().includes(textFilter.toLowerCase()) ||
      site.siteOwnerships
        .map((ownership) => ownership.name!.toLowerCase())
        .join(" ")
        .includes(textFilter.toLowerCase())
  );
};

export const togglesortAscending = (setSortAscending) => {
  setSortAscending((prevsortAscending) => !prevsortAscending);
};

// if true default sort will be in ascending order.
export const defaultSortAscendingValues = {
  name: true,
  irr: false,
  networkAvailabilityLease: false,
  networkAvailabilityLicense: false,
  networkAvailabilityTenant: false,
  projectCost: true,
  payback: true,
  licenseRevenue: false,
  tenants: true,
  carbonSavings: false,
  roofArea: false,
  leaseRevenue: false,
  leaseRevenuePerArea: false,
  annualSavings: false,
  landlords: true,
  netUsage: true,
  energyProcured: false,
  inRangeScore: false,
  lastMonthCost: false,
  lastMonthProcurement: false,
  lastMonthRevenue: false,
  lastMonthSavings: false,
  lastMonthDemandFulfilled: false,
  lastMonthGeneration: false,
};
