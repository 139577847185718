import { useSiteFileDownload } from "@inrange/building-manager-api-client";
import {
  Site,
  SiteFile,
} from "@inrange/building-manager-api-client/models-site";
import { DateTime } from "luxon";
import React from "react";
import formatCurrency from "../../formatting/formatCurrency";
import DownloadFile from "./DownloadFile";
import TableCellData from "./TableCellData";

interface FileListRowProps {
  orgId: string;
  site: Site;
  userEmail: string;
  file: SiteFile;
  isBilling: boolean;
}

const formatFileDate = (date: number): string => {
  const dateObj = DateTime.fromSeconds(date);
  return dateObj.toFormat("d LLLL yyyy");
};

const FileListRow: React.FC<FileListRowProps> = ({
  orgId,
  site,
  userEmail,
  file,
  isBilling,
}) => {
  const { fetchSiteFileURL } = useSiteFileDownload({
    siteId: site?.id,
    file,
    isAttachment: true,
    enabled: false,
    userOrgId: orgId,
  });
  const description = file.description || file.fileName;
  const date = file.date;
  const issueDate = file.issueDate;
  const invoiceDueDate = file.invoiceDueDate;
  const invoiceStatementNumber = file.invoiceNumber || file.statementNumber;
  const invoiceStatementAmount = file.invoiceAmount || file.statementAmount;

  return (
    <tr key={file.fileGuid} id={file.fileGuid}>
      <td align="left">
        <TableCellData data-testid={`${file.fileName}-row-description`}>
          {description}
        </TableCellData>
      </td>
      {isBilling ? (
        <>
          <td align="left" data-testid={`${file.fileName}-row-number`}>
            <TableCellData>{invoiceStatementNumber}</TableCellData>
          </td>
          <td align="left">
            <TableCellData data-testid={`${file.fileName}-row-amount`}>
              {formatCurrency(
                invoiceStatementAmount || 0,
                site.currencyCode,
                2
              )}
            </TableCellData>
          </td>
          <td align="left">
            <TableCellData data-testid={`${file.fileName}-row-issue-date`}>
              {issueDate ? formatFileDate(issueDate) : "-"}
            </TableCellData>
          </td>
          <td align="left">
            <TableCellData data-testid={`${file.fileName}-row-due-date`}>
              {invoiceDueDate ? formatFileDate(invoiceDueDate) : "-"}
            </TableCellData>
          </td>
        </>
      ) : (
        <td align="left">
          <TableCellData>{date ? formatFileDate(date) : "-"}</TableCellData>
        </td>
      )}
      <td align="right">
        <DownloadFile
          downloadDisabled={fetchSiteFileURL.isLoading}
          downloadURL={fetchSiteFileURL.data?.url}
          fileName={file.fileName}
          userEmail={userEmail}
          site={site}
          refetchURL={fetchSiteFileURL.refetch}
        />
      </td>
    </tr>
  );
};

export default FileListRow;
