import React from "react";
import { formatCurrencyToNearestTen } from "../../formatting/formatCurrency";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import formatMass from "../../formatting/formatMass";
import { MetricCardsRow, SiteMetricCard } from "./index";

interface Metrics {
  totalSites: number;
  totalRevenue: number;
  totalProjectCost: number;
  totalOnSiteGeneration: number;
  totalSavings: number;
  totalEmissionsAvoided: number;
  totalEnergyProcured: number;
}

interface LandlordSiteListMetricCardsProps {
  metrics: Metrics;
  isLeaseMetrics: boolean;
  isTenantOwnerOccupiedSiteList: boolean;
  investmentModel: string;
  summaryCurrencyCode: string;
}

export const LandlordSiteListMetricCards: React.FC<
  LandlordSiteListMetricCardsProps
> = ({
  metrics,
  isLeaseMetrics,
  isTenantOwnerOccupiedSiteList,
  investmentModel,
  summaryCurrencyCode,
}) => {
  const investmentModelString =
    investmentModel === "lease" ? "lease" : "licensed";
  return (
    <MetricCardsRow>
      <SiteMetricCard
        title="Total sites"
        value={metrics.totalSites}
        color="grey"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        dataTestId="total-sites"
      />
      <SiteMetricCard
        title={`Total revenue in year 1 (${investmentModelString})`}
        value={formatCurrencyToNearestTen(
          metrics.totalRevenue,
          summaryCurrencyCode,
          0,
          false
        )}
        tooltipText="Revenue generated across all sites in year 1, not accounting for any applicable project costs."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        dataTestId="total-revenue"
      />
      {!isLeaseMetrics && (
        <SiteMetricCard
          title={`Total project cost (${investmentModelString})`}
          value={formatCurrencyToNearestTen(
            metrics.totalProjectCost,
            summaryCurrencyCode,
            0,
            false
          )}
          tooltipText="The total cost of the projects including installation and maintenance across all sites that are under the license option."
          tooltipPosition="left"
          color="white"
          height="168px"
          width="100%"
          titleColor="#5486AD"
          dataTestId="total-project-cost"
        />
      )}
      <SiteMetricCard
        title="Total on-site generation in year 1"
        value={formatUnitsToNearestTen(metrics.totalOnSiteGeneration, "kWh")}
        tooltipText="Energy generated in the first year across all sites."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        dataTestId="total-on-site-generation"
      />
      {isLeaseMetrics && (
        <SiteMetricCard
          title="Total savings in year 1"
          value={formatCurrencyToNearestTen(
            metrics.totalSavings,
            summaryCurrencyCode,
            0,
            false
          )}
          tooltipText={`The total annual savings ${isTenantOwnerOccupiedSiteList ? "you’ll" : "your tenants will"} enjoy from procuring energy from InRange. Calculated as the difference between the current market tariff (as stated) and a per site renewable energy tariff and demand.`}
          tooltipPosition="left"
          color="white"
          height="168px"
          width="100%"
          titleColor="#5486AD"
          dataTestId="total-savings"
        />
      )}
      <SiteMetricCard
        title="Total emissions avoided in year 1"
        value={formatMass(metrics.totalEmissionsAvoided) + " CO2e"}
        tooltipText="Emissions avoided in the first year after installation across all sites where the CO2e multiplier per MWh is based on the annual carbon intensity of the grid at each site location."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        dataTestId="total-emissions-avoided"
      />
      {isLeaseMetrics && (
        <SiteMetricCard
          title="Total procured from InRange in year 1"
          value={formatUnitsToNearestTen(metrics.totalEnergyProcured!, "kWh")}
          tooltipText="The amount of solar energy you procure from InRange in the first year. Includes behind the meter consumption from your own generation as well as energy imported from the InRange network."
          tooltipPosition="left"
          color="white"
          height="168px"
          width="100%"
          titleColor="#5486AD"
          dataTestId="total-energy-procured"
        />
      )}
      {!isLeaseMetrics && (
        <SiteMetricCard
          title={`Total${isTenantOwnerOccupiedSiteList ? "" : " tenant"} savings in year 1`}
          value={formatCurrencyToNearestTen(
            metrics.totalSavings,
            summaryCurrencyCode,
            0,
            false
          )}
          tooltipText={`The total annual savings ${isTenantOwnerOccupiedSiteList ? "you’ll" : "your tenants will"} enjoy from procuring energy from InRange. Calculated as the difference between the current market tariff (as stated) and a per site renewable energy tariff and demand.`}
          tooltipPosition="left"
          color="white"
          height="168px"
          width="100%"
          titleColor="#5486AD"
          dataTestId="total-savings"
        />
      )}
    </MetricCardsRow>
  );
};
