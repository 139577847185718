import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../auth/UserContext";
import LandlordSiteListView from "../../views/LandlordSiteListView";
import OperationalSiteListView from "../../views/OperationalSiteListView";
import TenantSiteListView from "../../views/TenantSiteListView";
import {
  generateOwnershipFilteringObject,
  generateOwnershipFilteringSlackNotification,
  getSitesByType,
} from "./dashboard-utils";

interface TenantSiteListProps {
  organisation: Organisation;
  sites: OrgSiteListEntry[];
  isLeaseMetrics: boolean;
}

const TenantSiteList: React.FC<TenantSiteListProps> = ({
  organisation,
  sites,
  isLeaseMetrics,
}) => {
  const { user } = useContext(UserContext);
  const userEmail = user!.email;
  let { siteType } = useParams<{ siteType?: string }>();
  const orgID = organisation.id;
  const sitesByType = getSitesByType(sites, orgID, [
    "tenant",
    "ownerOccupier",
    "operational",
  ]);

  // Generate filtering object for landlord
  // Shape - { [orgID]: { name: string, siteIDs: string[] } }
  const landlordFilteringObject = generateOwnershipFilteringObject(
    sitesByType["tenant"],
    "landlord"
  );

  if (siteType === undefined) {
    if (sitesByType["tenant"].length > 0) {
      siteType = "tenant";
    } else if (sitesByType["ownerOccupier"].length > 0) {
      siteType = "ownerOccupier";
    } else {
      siteType = "operational";
    }
  }

  if (siteType === "ownerOccupier") {
    return (
      <LandlordSiteListView
        sites={sitesByType["ownerOccupier"]}
        organisation={organisation}
        isTenantOwnerOccupiedSiteList={true}
        isLeaseMetrics={isLeaseMetrics}
      />
    );
  }

  if (siteType === "operational") {
    return (
      <OperationalSiteListView
        operationalSites={sitesByType["operational"]}
        organisation={organisation}
        startingSortColumn={"name"}
      />
    );
  }

  const onLandlordFiltering = (
    filterAction: string,
    filterOrgNames: string[]
  ) => {
    generateOwnershipFilteringSlackNotification(
      filterAction,
      organisation.name,
      filterOrgNames,
      userEmail
    );
  };

  return (
    <TenantSiteListView
      sites={sitesByType["tenant"]}
      organisation={organisation}
      landlordFilteringObject={landlordFilteringObject}
      onLandlordFiltering={onLandlordFiltering}
    />
  );
};

export default TenantSiteList;
