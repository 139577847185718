import {
  isOrganizationInSiteScores,
  NoPaddedRow,
  TableListSortFilters,
} from "@inrange/theme-components";
import { TenantSiteListMetricCards } from "@inrange/theme-components/site";
import {
  Table,
  TenantSiteHeader,
  TenantSiteRow,
} from "@inrange/theme-components/table";
import React, { useEffect, useState } from "react";
import {
  defaultSortAscendingValues,
  filterSitesByText,
  sortByColumn,
  togglesortAscending,
} from "./SiteListSortMethods";

import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import {
  calcAverageMarketTariff,
  calcAverageTariff,
  calcEmissionsAvoided,
  calcSummaryCurrencyCode,
  calcTotalAnnualDemand,
  calcTotalEnergyProcuredCost,
  calcTotalSavings,
} from "../routes/dashboard/aggregationCalculations";
import {
  loadSelectedOrgIds,
  saveSelectedOrgIDs,
  sortOwnershipSelectOptions,
} from "./sitelist-utils";

const calcLandlordFrequency = (
  sites: OrgSiteListEntry[]
): Record<string, number> => {
  const landlords: Record<string, number> = {};
  for (const site of sites) {
    // Set a baseline of -1 (for sites with an unknown landlord)
    site.landlordCount = -1;

    for (const siteOwnership of site.siteOwnerships) {
      if (siteOwnership.ownership === "landlord") {
        if (landlords[siteOwnership.name!]) {
          landlords[siteOwnership.name!] += 1;
        } else {
          landlords[siteOwnership.name!] = 1;
        }
      }
      if (siteOwnership.ownership === "ownerOccupier") {
        site.landlordCount = 0;
      }
    }
  }
  // Iterate back through the list to set the landlord count
  for (const site of sites) {
    for (const siteOwnership of site.siteOwnerships) {
      if (siteOwnership.ownership === "landlord") {
        site.landlordCount = landlords[siteOwnership.name!];
      }
    }
  }

  return landlords;
};

interface TenantSiteListViewProps {
  sites: OrgSiteListEntry[];
  organisation: Organisation;
  landlordFilteringObject: Record<string, { name: string; siteIDs: string[] }>;
  onLandlordFiltering: (filterAction: string, filterOrgNames: string[]) => void;
}

const TenantSiteListView: React.FC<TenantSiteListViewProps> = ({
  sites,
  organisation,
  landlordFilteringObject,
  onLandlordFiltering,
}) => {
  const [textFilter, setTextFilter] = useState<string>("");
  const [sortAscending, setSortAscending] = useState<boolean>(false);
  const [selectedLandlordOrgIDs, setSelectedLandlordOrgIDs] = useState<
    string[]
  >(() => loadSelectedOrgIds(organisation.id, "landlord"));
  const [filteredLandlordSiteIDs, setFilteredLandlordSiteIDs] = useState<
    Set<string>
  >(() => {
    const newFilteredLandlordSiteIDs = new Set<string>();
    const selectedLandlordOrgIDs = loadSelectedOrgIds(
      organisation.id,
      "landlord"
    );
    for (const orgID of selectedLandlordOrgIDs) {
      const siteIDs = landlordFilteringObject[orgID].siteIDs;
      siteIDs.forEach((siteID) => newFilteredLandlordSiteIDs.add(siteID));
    }
    return newFilteredLandlordSiteIDs;
  });

  const orgID = organisation.id;
  const displayInRangeScoreCol = isOrganizationInSiteScores(orgID);
  const allSitesNotStarted = sites.every(
    (site) => site.operationalStatus === "notStarted"
  );
  const [sortColumn, setSortColumn] = useState<string>(
    displayInRangeScoreCol || !allSitesNotStarted
      ? "siteStatusTenant"
      : "networkAvailabilityTenant"
  );
  sortByColumn(sites, sortColumn, sortAscending, organisation); // Initial sort
  const [filteredSites, setFilteredSites] = useState<OrgSiteListEntry[]>(sites);

  // Required metrics
  const siteCount = filteredSites.length;
  const summaryCurrencyCode = calcSummaryCurrencyCode(filteredSites);
  const totalAnnualDemand = calcTotalAnnualDemand(filteredSites);
  const totalEnergyProcuredCost = calcTotalEnergyProcuredCost(
    filteredSites,
    summaryCurrencyCode
  );
  const emissionsAvoided = calcEmissionsAvoided(filteredSites);
  const totalSavings = calcTotalSavings(
    filteredSites,
    organisation,
    summaryCurrencyCode
  );
  const averageTariff = calcAverageTariff(filteredSites, summaryCurrencyCode);
  const averageMarketTariff = calcAverageMarketTariff(
    filteredSites,
    summaryCurrencyCode
  );

  // Chart data

  const metrics = {
    totalSites: siteCount,
    totalAnnualDemand,
    averageTariff,
    totalEnergyProcuredCost,
    emissionsAvoided,
    totalSavings,
    averageMarketTariff,
  };

  useEffect(() => {
    calcLandlordFrequency(sites);
  }, [sites]);

  useEffect(() => {
    saveSelectedOrgIDs(organisation.id, "landlord", selectedLandlordOrgIDs);
  }, [organisation, selectedLandlordOrgIDs]);

  // Sort and filter sites
  useEffect(() => {
    let listedSites = [...sites];
    if (filteredLandlordSiteIDs.size > 0) {
      listedSites = listedSites.filter((site) =>
        filteredLandlordSiteIDs.has(site.id)
      );
    }

    // Text search based on name, address or tenant name
    if (textFilter.length > 0) {
      listedSites = filterSitesByText(sites, textFilter);
    }

    sortByColumn(listedSites, sortColumn, sortAscending, organisation);
    setFilteredSites(listedSites);
  }, [
    filteredLandlordSiteIDs,
    textFilter,
    sites,
    organisation,
    sortColumn,
    sortAscending,
  ]);

  const handleColumnClick = (columnKey: string) => {
    if (sortColumn === columnKey) {
      togglesortAscending(setSortAscending);
      return;
    }
    setSortColumn(columnKey);
    setSortAscending(defaultSortAscendingValues[columnKey]);
  };

  const tableHead = (
    <TenantSiteHeader
      displayInRangeScoreCol={displayInRangeScoreCol}
      allSitesNotStarted={allSitesNotStarted}
      sortColumn={sortColumn}
      sortAscending={sortAscending}
      handleColumnClick={handleColumnClick}
    />
  );
  const tableRows = filteredSites.map((site) => (
    <TenantSiteRow
      key={site.id}
      site={site}
      organisation={organisation}
      displayInRangeScoreCol={displayInRangeScoreCol}
      allSitesNotStarted={allSitesNotStarted}
    />
  ));

  const landlordSelectOptions = sortOwnershipSelectOptions(
    Object.entries(landlordFilteringObject).map(([orgID, filterObject]) => ({
      id: orgID,
      value: filterObject.siteIDs,
      label: filterObject.name,
    }))
  );

  return (
    <>
      <TenantSiteListMetricCards
        metrics={metrics}
        summaryCurrencyCode={summaryCurrencyCode}
      />
      <NoPaddedRow>
        <TableListSortFilters
          textFilter={textFilter}
          setTextFilter={setTextFilter}
          selectFiltering={{
            setFilteredSiteIDs: setFilteredLandlordSiteIDs,
            setSelectedOrgIDs: setSelectedLandlordOrgIDs,
            onOwnershipFiltering: onLandlordFiltering,
            selectedOrgIDs: selectedLandlordOrgIDs,
            ownershipSelectOptions: landlordSelectOptions,
            isLandlord: false,
          }}
        />
      </NoPaddedRow>
      <NoPaddedRow>
        <Table
          head={tableHead}
          rows={tableRows}
          id={"tenant-site-list-table"}
        />
      </NoPaddedRow>
    </>
  );
};

export default TenantSiteListView;
