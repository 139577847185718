import ReactDOM from "react-dom/client";

import {
  extraErrorDataIntegration,
  httpClientIntegration,
} from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import reportWebVitals from "./reportWebVitals";

import {
  KINDE_ENVIRONMENT,
  KindeAuthenticatedUI,
  KindeRedirectProvider,
} from "@inrange/auth";

import { GlobalStyle, ThemeProvider } from "@inrange/theme-components/styles";
import { QueryClientProvider } from "react-query";
import AppRoutes from "./AppRoutes";
import { UserProvider } from "./auth/UserContext";
import queryClient from "./queryClient";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://eb8365ed1ef648f99ae52eb6bc21529a@o1336164.ingest.sentry.io/4504717185777664",
    integrations: [
      // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      httpClientIntegration({
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
        failedRequestStatusCodes: [
          // Don't alert on 400 Bad Request in Customer as we expect this from:
          // - token requests to the Kinde endpoint when the user doesn't have a token
          [401, 499],
          [500, 599],
        ],
      }),
      extraErrorDataIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_BRANCH_NAME,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <KindeRedirectProvider>
    <KindeAuthenticatedUI
      kindeClientId={KINDE_ENVIRONMENT.KINDE_CUSTOMER_APP_CLIENT_ID}
      isDangerouslyUseLocalStorage={
        !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE
      }
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <GlobalStyle />
          <UserProvider>
            <AppRoutes />
          </UserProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </KindeAuthenticatedUI>
  </KindeRedirectProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
