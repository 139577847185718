import { OrgSiteListEntry } from "@inrange/building-manager-api-client/models-organisation";
import {
  Site,
  SiteOwnership,
} from "@inrange/building-manager-api-client/models-site";

const calcSiteOwnershipTypeForOrg = (
  site: Site | OrgSiteListEntry,
  orgId: string
): string => {
  return (
    site.siteOwnerships.find((so: SiteOwnership) => {
      return so.orgID === orgId;
    })?.ownership || "unknown"
  );
};

export default calcSiteOwnershipTypeForOrg;
