import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import {
  NoPaddedRow,
  TableListSortFilters,
  TableListToggleButtons,
} from "@inrange/theme-components";
import {
  OperationalSiteListHeader,
  OperationalSiteRow,
  Table,
} from "@inrange/theme-components/table";
import React, { useEffect, useMemo, useState } from "react";
import {
  defaultSortAscendingValues,
  filterSitesByText,
  sortByColumn,
  togglesortAscending,
} from "./SiteListSortMethods";
import {
  getBehindMeterCost,
  getLatestActualsMonthIndex,
} from "./sitelist-utils";

interface OperationalSiteListViewProps {
  operationalSites: OrgSiteListEntry[];
  organisation: Organisation;
  startingSortColumn: string;
}

const groupSitesByOwnedOrLeased = (
  sites: OrgSiteListEntry[],
  orgID: string
) => {
  const ownedOwnerships = ["ownerOccupier", "landlord"]; // Here we treat landlord and owner occupier as owned
  return sites.reduce(
    (acc, site) => {
      const siteOwnership = site.siteOwnerships.find(
        (siteOwnership) => siteOwnership.orgID === orgID
      )!;
      if (ownedOwnerships.includes(siteOwnership.ownership)) {
        acc["owned"].push(site);
      } else {
        acc["leased"].push(site);
      }
      return acc;
    },
    { owned: [] as OrgSiteListEntry[], leased: [] as OrgSiteListEntry[] }
  );
};

const OperationalSiteListView: React.FC<OperationalSiteListViewProps> = ({
  operationalSites,
  organisation,
  startingSortColumn,
}) => {
  const params = new URL(document.location.toString()).searchParams;
  const testMonthToday = Number(params.get("testMonthToday"));

  const sitesByOwnedOrLeased = useMemo(
    () => groupSitesByOwnedOrLeased(operationalSites, organisation.id),
    [operationalSites, organisation.id]
  );
  const showOwnershipModelSelector =
    sitesByOwnedOrLeased["owned"].length > 0 &&
    sitesByOwnedOrLeased["leased"].length > 0;
  const defaultOwnershipModel =
    sitesByOwnedOrLeased["owned"].length > 0 ? "owned" : "leased";
  const defaultSites = sitesByOwnedOrLeased[defaultOwnershipModel];
  const [textFilter, setTextFilter] = useState("");
  const [filteredSites, setFilteredSites] =
    useState<OrgSiteListEntry[]>(defaultSites);
  const [sortColumn, setSortColumn] = useState(startingSortColumn);
  const [sortAscending, setSortAscending] = useState(false);
  const [ownershipModel, setOwnershipModel] = useState(defaultOwnershipModel);

  const orgID = organisation.id;

  const changeOwnershipModel = (model: string) => {
    setSortColumn(startingSortColumn);
    setOwnershipModel(model);
    setFilteredSites(sitesByOwnedOrLeased[model]);
  };

  const modelSelectionTabs = {
    tab1: {
      label: "Owned",
      active: ownershipModel === "owned",
      onClick: () => {
        changeOwnershipModel("owned");
      },
      count: sitesByOwnedOrLeased["owned"].length,
    },
    tab2: undefined,
    tab3: {
      label: "Leased",
      active: ownershipModel === "leased",
      onClick: () => {
        changeOwnershipModel("leased");
      },
      count: sitesByOwnedOrLeased["leased"].length,
    },
  };

  // Sort and filter
  useEffect(() => {
    let listedSites = [...sitesByOwnedOrLeased[ownershipModel]];
    if (textFilter.length > 0) {
      listedSites = filterSitesByText(listedSites, textFilter);
    }
    sortByColumn(listedSites, sortColumn, sortAscending, organisation);
    setFilteredSites(listedSites);
  }, [
    textFilter,
    organisation,
    sortColumn,
    sortAscending,
    sitesByOwnedOrLeased,
    ownershipModel,
  ]);

  const handleColumnClick = (columnKey: string) => {
    if (sortColumn === columnKey) {
      togglesortAscending(setSortAscending);
      return;
    }
    setSortColumn(columnKey);
    setSortAscending(defaultSortAscendingValues[columnKey]);
  };

  const tableHead = (
    <OperationalSiteListHeader
      mode={ownershipModel}
      sortColumn={sortColumn}
      sortAscending={sortAscending}
      handleColumnClick={handleColumnClick}
    />
  );

  const tableRows = filteredSites.map((site) => {
    let lastMonthCost = 0;
    let lastMonthProcurement = 0;
    let lastMonthRevenue = 0;
    let lastMonthDemandFulfilled = 0;
    let lastMonthSavings = 0;
    let lastMonthGeneration = 0;
    if (site.energyFlowMonthlyActuals !== undefined) {
      const lastMonthIndex = getLatestActualsMonthIndex(site, testMonthToday);

      if (lastMonthIndex !== undefined) {
        const siteOwnership = site.siteOwnerships.find(
          (siteOwnership) => siteOwnership.orgID === orgID
        );
        const lastMonthEnergyFlows =
          site.energyFlowMonthlyActuals[lastMonthIndex].energyFlow;
        const lastMonthFinancialModels =
          site.financialModelsMonthlyActuals![lastMonthIndex];

        // lastMonthCost
        lastMonthCost = getBehindMeterCost(lastMonthEnergyFlows.matchedEnergy);

        // lastMonthProcurement
        lastMonthProcurement = lastMonthEnergyFlows.procurement;

        // lastMonthRevenue
        const revenueOwnershipKey =
          siteOwnership?.ownership === "ownerOccupier"
            ? "ownerOccupier"
            : "landlord";
        lastMonthRevenue =
          lastMonthFinancialModels[revenueOwnershipKey][
            site.activeInvestmentModel
          ].revenue;

        // lastMonthDemandFulfilled
        lastMonthDemandFulfilled =
          lastMonthEnergyFlows.procurement / lastMonthEnergyFlows.demand;

        // lastMonthSavings
        const savingsOwnershipKey =
          siteOwnership?.ownership === "ownerOccupier"
            ? "ownerOccupier"
            : "tenant";
        lastMonthSavings =
          savingsOwnershipKey === "ownerOccupier"
            ? lastMonthFinancialModels[savingsOwnershipKey][
                site.activeInvestmentModel
              ].savings
            : lastMonthFinancialModels[savingsOwnershipKey].savings;

        // lastMonthGeneration
        lastMonthGeneration = lastMonthEnergyFlows.generation;
      }
    }

    return (
      <OperationalSiteRow
        key={site.id}
        site={site}
        organisation={organisation}
        mode={ownershipModel}
        lastMonthCost={lastMonthCost}
        lastMonthProcurement={lastMonthProcurement}
        lastMonthRevenue={lastMonthRevenue}
        lastMonthDemandFulfilled={lastMonthDemandFulfilled}
        lastMonthSavings={lastMonthSavings}
        lastMonthGeneration={lastMonthGeneration}
      />
    );
  });

  return (
    <>
      <NoPaddedRow style={{ marginTop: "8px" }}>
        {showOwnershipModelSelector && (
          <TableListToggleButtons tabs={modelSelectionTabs} />
        )}
        <TableListSortFilters
          textFilter={textFilter}
          setTextFilter={setTextFilter}
        />
      </NoPaddedRow>
      <NoPaddedRow>
        <Table head={tableHead} rows={tableRows} />
      </NoPaddedRow>
    </>
  );
};

export default OperationalSiteListView;
