import { DesktopOnly } from "@inrange/theme-components";
import React, { useEffect, useState } from "react";

interface WindowDimensions {
  width: number;
}

const useWindowDimensions = (): WindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    width: 1051, // Assume window is wide enough until we know otherwise
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

const TooNarrow: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { width } = useWindowDimensions();
  return width > 1050 ? children : <DesktopOnly />;
};

export default TooNarrow;
