import {
  AgregateEnergyFlow,
  Calculations,
  CostInputsBattery,
  FinancialModelScenarios,
  ProjectCosts,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import { calcCfeScore } from "@inrange/calculations/calcCfeScore";
import { Column, SummaryBoxMetric } from "@inrange/theme-components";
import { ChartData } from "@inrange/theme-components/charts";
import {
  formatCurrencyToNearestTen,
  formatUnitsToNearestTen,
  tariffToFractionalCurrency,
} from "@inrange/theme-components/formatting";
import {
  LandlordSiteMetricValues,
  TenantSiteMetricValues,
} from "@inrange/theme-components/site";
import { Link } from "react-router-dom";
import {
  getProposalCostOfEnergyProcuredChartData,
  getProposalEnergySoldChartData,
  getProposalRevenueChartData,
} from "../dashboard/chartDataFormatting";

interface SharedSite {
  id: string;
  name: string;
}

interface SummaryBox {
  header: string;
  metrics: SummaryBoxMetric[] | SummaryBoxMetric[][];
  grid: boolean;
  summaryChildren?: JSX.Element | (JSX.Element | undefined)[];
}

interface ChartDefinition {
  chartData: ChartData[];
  header: string;
  tooltipText: string;
  keys: string[];
  currency: boolean;
  barColors?: string[];
}

interface ProposalSummaryMetrics {
  metricCards?: TenantSiteMetricValues | LandlordSiteMetricValues;
  charts: ChartDefinition[];
  summaryBoxes: SummaryBox[];
}

const generateProposalSummaryMetrics = (
  proposalType: string,
  isGeneratingSite: boolean,
  site: Site,
  investmentModel: string,
  setInvestmentModel: (model: string) => void,
  hhDataUploaded: boolean,
  sharedSitesWithLandlord: SharedSite[],
  sellEnergyBlock: JSX.Element | undefined,
  generateBuyEnergyBlock: (unmetDemand: boolean) => JSX.Element | undefined,
  orgId: string
): ProposalSummaryMetrics => {
  if (investmentModel === "none")
    return {
      metricCards: undefined,
      charts: [],
      summaryBoxes: [],
    };

  const {
    costInputsBattery,
    projectCosts,
    energyFlowAnnual: forecastedEnergyFlowAnnual,
    networkImportTariff,
    marketTariff,
    totalBuildingArea,
    calculations,
    siteOwnerships,
  } = site;

  let behindTheMeterTariff = site.tenantTariff;
  const gridConnectionCost =
    investmentModel === "license" ? site.costInputsGrid.gridConnectionCost : 0;

  const forecastedBehindMeter = forecastedEnergyFlowAnnual.behindMeter;
  const forecastedNetworkImport = forecastedEnergyFlowAnnual.networkImport;
  const forecastedInRangeProcurement = forecastedEnergyFlowAnnual.procurement;

  if (proposalType === "ownerOccupier" && investmentModel === "license") {
    behindTheMeterTariff = 0;
  }
  const forecastedBehindMeterCost =
    forecastedBehindMeter * behindTheMeterTariff;
  const forecastedNetworkImportCost =
    forecastedNetworkImport * networkImportTariff;
  const forecastedInRangeProcurementCost =
    forecastedBehindMeterCost + forecastedNetworkImportCost;

  const otherInvestmentModel =
    investmentModel === "license" ? "lease" : "license";
  const projectCostLeaseLicenseToggleText =
    investmentModel === "license"
      ? " for zero hardware, installation, and grid connection costs."
      : " to own the installation and earn more revenue.";

  const projectCostsLeaseLicenseToggle = (
    <Column
      $margin={"0 15px 15px 15px"}
      $padding={"0 8px"}
      $justifyContent={"flex-end"}
    >
      <div
        style={{
          fontSize: "16px",
        }}
      >
        <span
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => {
            setInvestmentModel(otherInvestmentModel);
            document.body.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          Switch to {otherInvestmentModel}
        </span>
        <span>{projectCostLeaseLicenseToggleText}</span>
      </div>
    </Column>
  );
  const sharedSitesWithLandlordList = sharedSitesWithLandlord.length > 0 && (
    <Column
      $margin={"0 15px 15px 15px"}
      $padding={"0 8px"}
      $justifyContent={"flex-end"}
    >
      <div style={{ fontWeight: 550, fontSize: 18 }}>
        Other sites with landlord:
      </div>
      <ul style={{ marginTop: 0, paddingLeft: 20 }}>
        {sharedSitesWithLandlord.slice(0, 3).map((site, index) => {
          return (
            <li key={index}>
              <Link
                to={`/org/${orgId}/site/${site.id}`}
                style={{ color: "white" }}
                onClick={() => {
                  document.body.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                {site.name}
              </Link>
            </li>
          );
        })}
        {sharedSitesWithLandlord.length > 3 && (
          <li>Plus {sharedSitesWithLandlord.length - 3} more sites...</li>
        )}
      </ul>
    </Column>
  );

  const isTenant = proposalType === "tenant";
  const isOwnerOccupier = proposalType === "ownerOccupier";
  const isOwnerOccupierLicense =
    isOwnerOccupier && investmentModel === "license";

  const hhDemandDataString = hhDataUploaded
    ? ""
    : " Submitting your half hourly data will increase accuracy.";

  const costOfEnergyProcuredChart = {
    chartData: getProposalCostOfEnergyProcuredChartData(
      site,
      isOwnerOccupierLicense
    ),
    header: "Cost of energy procured by month",
    tooltipText: `The forecasted cost of energy procured based on ${
      isGeneratingSite ? "on-site and network tariffs" : "the network tariff"
    } and forecasted demand.${hhDemandDataString}`,
    keys:
      isGeneratingSite && !isOwnerOccupierLicense
        ? ["On-site behind the meter", "From InRange network"]
        : ["From InRange network"],
    currency: true,
  };
  const generateTariffMarketplaceBlock = (proposalType: string) => {
    switch (proposalType) {
      case "ownerOccupier":
        return [generateBuyEnergyBlock(false), sellEnergyBlock];
      case "landlord":
        return sellEnergyBlock;
      default:
        // "tenant"
        if (!isGeneratingSite) {
          return generateBuyEnergyBlock(false);
        }
    }
    return undefined;
  };
  const procurementBySourceSummary: SummaryBox = {
    header: "Procurement by source",
    metrics: generateProcurementMetricsSource(
      forecastedEnergyFlowAnnual,
      isGeneratingSite
    ),
    grid: false,
    summaryChildren:
      proposalType !== "landlord" ? generateBuyEnergyBlock(true) : undefined,
  };

  const proposalSummaryMetrics: ProposalSummaryMetrics = {
    metricCards: generateSiteMetricValues(
      proposalType,
      hhDataUploaded,
      marketTariff,
      site.financialModels,
      projectCosts,
      forecastedEnergyFlowAnnual,
      forecastedInRangeProcurement,
      forecastedInRangeProcurementCost,
      investmentModel,
      totalBuildingArea,
      calculations
    ),
    charts:
      proposalType === "tenant"
        ? [costOfEnergyProcuredChart]
        : [
            {
              chartData: getProposalRevenueChartData(
                site,
                investmentModel,
                isOwnerOccupierLicense
              ),
              header: "Revenue by month",
              tooltipText:
                "The forecasted revenue generated from energy sold after the given revenue share during a given month, where energy be sold on-site or exported to the InRange network, at the relevant tariffs. Does not include VAT.",
              keys: isOwnerOccupierLicense
                ? ["Exported to network"]
                : ["On-site to tenant", "Exported to network"],
              barColors: isOwnerOccupierLicense ? ["#2779A7"] : undefined,
              currency: true,
            },
            {
              chartData: getProposalEnergySoldChartData(site),
              header: "Energy sold by month",
              tooltipText:
                "The forecasted amount of energy sold in kWh during a given month, where energy can be sold on-site or exported to the InRange network.",
              keys: ["On-site to tenant", "Exported to network"],
              currency: false,
            },
          ],
    summaryBoxes: [
      {
        header: "Tariffs",
        metrics: generateTariffMetrics(
          site,
          behindTheMeterTariff,
          proposalType,
          isGeneratingSite
        ),
        grid: true,
        summaryChildren: generateTariffMarketplaceBlock(proposalType),
      },
    ],
  };

  if (isOwnerOccupier) {
    proposalSummaryMetrics.charts.push(costOfEnergyProcuredChart);
  }

  if (isTenant) {
    if (isGeneratingSite) {
      proposalSummaryMetrics.summaryBoxes.push(
        {
          header: "Procurement in year 1",
          metrics: generateProcurementMetricsMonetary(
            site.currencyCode,
            forecastedBehindMeterCost,
            forecastedNetworkImportCost,
            forecastedInRangeProcurementCost
          ),
          grid: false,
          summaryChildren: generateBuyEnergyBlock(false),
        },
        procurementBySourceSummary
      );

      const landlordName = siteOwnerships.find(
        (ownership) => ownership.ownership === "landlord"
      )?.name;

      landlordName &&
        proposalSummaryMetrics.summaryBoxes.push({
          header: "Landlord",
          metrics: generateTenantLandlord(landlordName),
          grid: false,
          summaryChildren: sharedSitesWithLandlordList || undefined,
        });
    } else {
      proposalSummaryMetrics.summaryBoxes.push(procurementBySourceSummary);
    }
  } else {
    proposalSummaryMetrics.summaryBoxes.push(
      {
        header: "Project costs",
        metrics: generateProjectCostsMetrics(
          site.currencyCode,
          costInputsBattery,
          projectCosts,
          gridConnectionCost,
          investmentModel
        ),
        grid: false,
        summaryChildren:
          site.operationalStatus === "notStarted"
            ? projectCostsLeaseLicenseToggle
            : undefined,
      },
      procurementBySourceSummary
    );
  }

  return proposalSummaryMetrics;
};

export default generateProposalSummaryMetrics;

const generateSiteMetricValues = (
  proposalType: string,
  hhDataUploaded: boolean,
  marketTariff: number,
  financialModels: FinancialModelScenarios,
  projectCosts: ProjectCosts,
  forecastedEnergyFlowAnnual: AgregateEnergyFlow,
  forecastedInRangeProcurement: number,
  forecastedInRangeProcurementCost: number,
  investmentModel: string,
  totalBuildingArea: number,
  calculations: Calculations
): TenantSiteMetricValues | LandlordSiteMetricValues => {
  const demandFulfilledByInRange =
    forecastedInRangeProcurement / forecastedEnergyFlowAnnual.demand;
  if (proposalType === "tenant")
    return {
      procurementCostYearOne: forecastedInRangeProcurementCost,
      procuredEnergyYearOne: forecastedInRangeProcurement,
      savingsYearOne: financialModels[proposalType].savings,
      marketTariff,
      totalDemandYearOne: forecastedEnergyFlowAnnual.demand,
      demandFulfilledByInRange,
      emissionsAvoidedYearOne: calculations.emissionsAvoided.totalAvoidance,
      cfeScore: calcCfeScore(forecastedEnergyFlowAnnual),
      hhDataUploaded,
    };

  const isLicense = investmentModel === "license";
  const isOwnerOccupier = proposalType === "ownerOccupier";

  const revenueYearOne = financialModels[proposalType][investmentModel].revenue;
  const siteMetricsValues: LandlordSiteMetricValues = {
    revenueYearOne: revenueYearOne,
    initialInvestment:
      projectCosts[isLicense ? "initialInvestment" : "leaseInitialInvestment"],
    lifetimeRevenue:
      financialModels[proposalType][investmentModel].lifetimeRevenue,
    totalProjectCost: projectCosts[isLicense ? "total" : "leaseTotal"],
    irr: financialModels[proposalType].license.irr,
    payback: financialModels[proposalType].license.paybackMonths,
    revenuePerFt2: revenueYearOne / totalBuildingArea,
    onSiteDemandYearOne: forecastedEnergyFlowAnnual.demand,
    marketTariff,
    demandFulfilledByInRange,
    procurementCostYearOne: forecastedInRangeProcurementCost,
    generationPotentialYearOne: forecastedEnergyFlowAnnual.generation,
    onSiteGenerationConsumed: forecastedEnergyFlowAnnual.tenantEnergyShare,
    emissionsAvoidedYearOne: calculations.emissionsAvoided.totalAvoidance,
    cfeScore: calcCfeScore(forecastedEnergyFlowAnnual),
    investmentModel: investmentModel,
    hhDataUploaded,
  };

  if (isOwnerOccupier) {
    siteMetricsValues.savingsYearOne =
      financialModels[proposalType][investmentModel].savings;
  }

  return siteMetricsValues;
};

const generateTariffMetrics = (
  site: Site,
  tenantTariff: number,
  proposalType: string,
  isGeneratingSite: boolean
): SummaryBoxMetric[] | SummaryBoxMetric[][] => {
  const isOwnerOccupier = proposalType === "ownerOccupier";
  const tenantTariffSubText = "On-site";
  const secondTariffSubText =
    proposalType === "landlord"
      ? "Avg. export"
      : ["InRange network", "excl. non-commodity costs"];

  const tariffMetrics: SummaryBoxMetric[] = [
    {
      value: tariffToFractionalCurrency(tenantTariff, site.currencyCode),
      valueSubText: tenantTariffSubText,
      dataTestId: "test-tariff",
    },
    {
      value: tariffToFractionalCurrency(
        proposalType === "landlord"
          ? site.blendedExportTariff
          : site.networkImportTariff,
        site.currencyCode
      ),
      dataTestId: isGeneratingSite ? "" : "test-tariff",
      valueSubText: secondTariffSubText,
    },
  ];

  if (!isGeneratingSite) return tariffMetrics.slice(1);

  if (isOwnerOccupier) {
    const ownerOccupierTariffMetrics = [
      tariffMetrics,
      [
        {
          value: tariffToFractionalCurrency(
            site.blendedExportTariff,
            site.currencyCode
          ),
          valueSubText: "Avg. export",
        },
      ],
    ];
    return ownerOccupierTariffMetrics;
  }

  return tariffMetrics;
};

const generateProjectCostsMetrics = (
  currencyCode: string,
  costInputsBattery: CostInputsBattery,
  projectCosts: ProjectCosts,
  gridConnectionCost: number,
  investmentModel: string
): SummaryBoxMetric[] => {
  const metrics: SummaryBoxMetric[] = [];
  const isLicense = investmentModel === "license";
  if (isLicense) {
    metrics.push({
      value: formatCurrencyToNearestTen(
        projectCosts[
          isLicense ? "initialCostPerKWp" : "leaseInitialCostPerKWp"
        ],
        currencyCode,
        0,
        false
      ),
      valueSubText: "Cost per kWp",
    });
  }
  metrics.push({
    value: formatCurrencyToNearestTen(
      projectCosts[isLicense ? "hardware" : "leaseHardware"],
      currencyCode,
      0,
      false
    ),
    valueSubText: "Hardware costs",
  });
  if (costInputsBattery.batteryCost > 0) {
    metrics.push({
      value: formatCurrencyToNearestTen(
        isLicense
          ? costInputsBattery.batteryCost
          : projectCosts.leaseBatteryCost,
        currencyCode,
        0,
        false
      ),
      valueSubText: "Battery costs",
    });
  }
  metrics.push({
    value: formatCurrencyToNearestTen(
      projectCosts[isLicense ? "installation" : "leaseInstallation"] +
        gridConnectionCost,
      currencyCode,
      0,
      false
    ),
    valueSubText: "Installation + grid connection",
  });
  metrics.push({
    value: formatCurrencyToNearestTen(
      projectCosts[isLicense ? "maintenance" : "leaseMaintenance"],
      currencyCode,
      0,
      false
    ),
    valueSubText: "Lifetime O&M costs",
  });

  return metrics;
};

const generateProcurementMetricsMonetary = (
  currencyCode: string,
  forecastedBehindMeterCost: number,
  forecastedNetworkImportCost: number,
  forecastedTotalProcurementCost: number
): SummaryBoxMetric[] => {
  return [
    {
      value: formatCurrencyToNearestTen(
        forecastedBehindMeterCost,
        currencyCode,
        0,
        false
      ),
      valueSubText: "On-site generation",
    },
    {
      value: formatCurrencyToNearestTen(
        forecastedNetworkImportCost,
        currencyCode,
        0,
        false
      ),
      valueSubText: "InRange network",
    },
    {
      value: formatCurrencyToNearestTen(
        forecastedTotalProcurementCost,
        currencyCode,
        0,
        false
      ),
      valueSubText: "Total",
    },
  ];
};

const generateProcurementMetricsSource = (
  forecastedEnergyFlowAnnual: AgregateEnergyFlow,
  isGeneratingSite: boolean
): SummaryBoxMetric[] => {
  const forecastedBehindMeter = forecastedEnergyFlowAnnual.behindMeter;
  const forecastedNetworkImport = forecastedEnergyFlowAnnual.networkImport;
  const forecastedGridImport = forecastedEnergyFlowAnnual.gridImport;
  const demand = forecastedEnergyFlowAnnual.demand;
  const behindMeterPercent = (forecastedBehindMeter / demand) * 100;
  const networkImportPercent = (forecastedNetworkImport / demand) * 100;
  const gridImportPercent = (forecastedGridImport / demand) * 100;
  const metrics: SummaryBoxMetric[] = [
    {
      value: formatUnitsToNearestTen(forecastedBehindMeter, "kWh"),
      valueSubText: `On-site solar energy (${behindMeterPercent.toFixed(1)}%)`,
    },
    {
      value: formatUnitsToNearestTen(forecastedNetworkImport, "kWh"),
      valueSubText: `InRange network (${networkImportPercent.toFixed(1)}%)`,
    },
    {
      value: formatUnitsToNearestTen(forecastedGridImport, "kWh"),
      valueSubText: `Other suppliers (${gridImportPercent.toFixed(1)}%)`,
    },
  ];

  if (!isGeneratingSite) return metrics.slice(1);

  return metrics;
};

const generateTenantLandlord = (
  landlordName: string | undefined
): SummaryBoxMetric[] => {
  return [{ value: landlordName ?? "N/A", valueSubText: undefined }];
};
