import React from "react";
import styled from "styled-components";
import ascending from "../../images/icons/ascending.svg";
import descending from "../../images/icons/descending.svg";
import { HeaderComponentProps } from "./TableHeaderCell";

// header for description, date, invoiceStatementNumber, invoiceStatementAmount, invoiceDueDate

export const DescriptionHeader: React.FC<HeaderComponentProps> = ({
  sortColumn,
  sortAscending,
  sortValue,
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Description
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
    </ColumnContainer>
  );
};

export const InvoiceStatementNumberHeader: React.FC<HeaderComponentProps> = ({
  sortColumn,
  sortValue,
  sortAscending,
}) => {
  return (
    <ColumnTextStyle>
      {"Invoice/statement #"}
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const InvoiceStatementAmountHeader: React.FC<HeaderComponentProps> = ({
  sortColumn,
  sortValue,
  sortAscending,
}) => {
  return (
    <ColumnTextStyle>
      Invoice/statement amount
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const InvoiceDueDateHeader: React.FC<HeaderComponentProps> = ({
  sortColumn,
  sortValue,
  sortAscending,
}) => {
  return (
    <ColumnTextStyle>
      Due date
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const IssueDateHeader: React.FC<HeaderComponentProps> = ({
  sortColumn,
  sortValue,
  sortAscending,
}) => {
  return (
    <ColumnTextStyle>
      Issue date
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const DateHeader: React.FC<HeaderComponentProps> = ({
  sortColumn,
  sortValue,
  sortAscending,
}) => {
  return (
    <ColumnTextStyle>
      Date
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColumnTextStyle = styled.div`
  display: flex;
  justify-content: start;
  gap: 5px;
`;

export const getSortingIcon = (
  sortColumn: string,
  sortValue: string,
  sortAscending: boolean
): JSX.Element => {
  if (sortColumn === sortValue) {
    return sortAscending ? (
      <img
        src={ascending}
        alt="sortIcon"
        style={{ width: "16px", height: "16px" }}
      />
    ) : (
      <img
        src={descending}
        alt="sortIcon"
        style={{ width: "16px", height: "16px" }}
      />
    );
  }
  return <div style={{ width: "16px", height: "16px" }}></div>;
};
