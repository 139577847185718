import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import { OrgSiteListEntry } from "@inrange/building-manager-api-client/models-organisation";
import {
  BuyOfferEnergyFlows,
  NetworkSite,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "../../styles/theme";
import SimpleButton from "../buttons/SimpleButton";
import YearDayEnergyChart from "../charts/YearDayEnergyChart";
import Dropdown from "../input/Dropdown";
import InputContainer from "../input/InputContainer";
import { Row, RowNoDefaults } from "../layout/Flex";
import Grid from "../layout/Grid";
import Modal from "../modal/Modal";
import ModalView from "../modal/ModalView";
import { genEnergyDemandChartData } from "./marketplaceChartFormatting";
import MetricCard, { SdmMatchFormattedValue } from "./MetricCard";

export interface FormattedSdmMatch {
  sizePercent: string;
  values: SdmMatchFormattedValue[];
}

interface MarketplaceOfferProps {
  siteId?: string;
  userEmail: string;
  sites: Array<{ id: string; name: string }>;
  mySite?: Site | OrgSiteListEntry;
  networkSite?: OrgSiteListEntry | NetworkSite;
  setSelectedSiteIdFn: (siteId?: string) => void;
  addSiteUrl: string;
  notifyAddSiteFn: () => void;
  type: string;
  volume?: string;
  isLoading: boolean;
  offer?: FormattedSdmMatch;
  buyOfferEnergyFlows?: BuyOfferEnergyFlows;
  acceptOfferFn: () => void;
}

const MarketplaceOffer: React.FC<MarketplaceOfferProps> = ({
  siteId,
  userEmail,
  sites,
  mySite,
  networkSite,
  setSelectedSiteIdFn,
  addSiteUrl,
  notifyAddSiteFn,
  type,
  volume,
  isLoading,
  offer,
  buyOfferEnergyFlows,
  acceptOfferFn,
}) => {
  const [acceptedOffer, setAcceptedOffer] = useState(false);
  const [energyProcuredChartPeriod, setEnergyProcuredChartPeriod] = useState<
    "year" | "summer" | "winter"
  >("year");
  const [showModal, setShowModal] = useState(false);

  const onViewEnergyShape = () => {
    setShowModal(true);
    trackUserInteraction(
      {
        site_id: mySite!.id,
        site_name: mySite!.name,
        site_operational_status: mySite!.operationalStatus,
        network_site_id: networkSite!.id,
      },
      "ENERGY_OFFER",
      "VIEWED_ENERGY_SHAPE",
      userEmail,
      "customer-app"
    );
  };

  const demandChartData =
    type === "buy" && buyOfferEnergyFlows
      ? genEnergyDemandChartData(buyOfferEnergyFlows, energyProcuredChartPeriod)
      : undefined;

  const titles =
    type === "buy"
      ? [
          "Energy procured from network",
          "Energy tariff",
          "Est. availability",
          "Savings in year\u00A01", // no break space
          "Total site demand fulfilled",
          "Emissions avoided in year\u00A01",
        ]
      : [
          "Excess energy sold",
          "Energy tariff",
          "Offtake availability",
          "Revenue in year 1",
          "Total lifetime revenue",
          "IRR",
        ];

  let description = "";
  if (siteId === undefined) {
    description =
      "If you have another site not in the list above you want to buy energy for, add it! You'll get more value from the InRange Marketplace with every additional site.";
  } else {
    if (isLoading) {
      if (type === "buy") {
        description = `Finding a half-hourly level supply offer for ${volume} of your site's unmet demand...`;
      } else {
        description = `Finding a half-hourly level sale offer for ${volume} of your site's excess energy...`;
      }
    } else {
      if (offer) {
        if (type === "buy") {
          description = `Great news! We found a match for ${offer.sizePercent} of your site's ${volume} of unmet demand.`;
        } else {
          description = `Great news! We found a match for ${offer.sizePercent} of your site's ${volume} of excess generation.`;
        }
      } else {
        if (type === "buy") {
          description = `We were unable to find a suitable Marketplace match automatically. Contact us and we'll work together to find the right match for your needs!`;
        } else {
          description = `You've already got our best offer! We couldn't find any matchable sites that gave you better IRR than you already have. Contact us to explore other selling opportunities.`;
        }
      }
    }
  }

  let buttonLabel = `Contact us to ${type}!`;
  let bottomRowMargin = "10px 0 0 0";
  if (siteId === undefined) {
    bottomRowMargin = "10px 0 10px 0";
  } else if (!isLoading && !offer) {
    buttonLabel = `Find me a match!`;
    bottomRowMargin = "0";
  }

  let confirmationText = `A member of our team will reach out shortly to complete your ${
    type === "buy" ? "procurement" : "sale"
  } offer request.`;
  if (!offer) {
    if (type === "buy") {
      confirmationText =
        "A member of our team will reach out shortly to explore other procurement opportunities.";
    } else {
      confirmationText =
        "A member of our team will reach out shortly to explore other selling opportunities.";
    }
  }

  return (
    <>
      <OfferPanel>
        <Header>Explore Marketplace</Header>
        <Row
          $padding={"0"}
          $margin={"0 0 10px 0"}
          $alignItems={"center"}
          $width={"616px"}
          $justifyContent={"stretch"}
        >
          <InputContainer style={{ flex: 1 }}>
            <Dropdown
              value={siteId}
              onChange={(e) => {
                setAcceptedOffer(false);
                setSelectedSiteIdFn(e.target.value || undefined);
              }}
              data-testid="marketplace-site-dropdown"
            >
              <option value={""}>Select a site to explore with</option>
              {sites.map((site, index) => (
                <option key={index} value={site.id}>
                  {site.name}
                </option>
              ))}
            </Dropdown>
          </InputContainer>
          {siteId !== undefined && (
            <AddSiteLinkText>
              or{" "}
              <Link to={addSiteUrl} onClick={notifyAddSiteFn}>
                Add a site
              </Link>
            </AddSiteLinkText>
          )}
        </Row>
        {siteId === undefined && (
          <Confirmation style={{ marginBottom: "20px" }}>
            Select a site or interact with sites on the map to see what the
            InRange Marketplace has to offer.
          </Confirmation>
        )}
        {siteId !== undefined && (
          <Header>
            InRange network {type === "buy" ? "procurement" : "sale"} offer
          </Header>
        )}
        <Grid $cols={3} $colwidth={"200px"} $gap={"8px"}>
          <Description
            style={{ gridColumn: "span 3" }}
            data-testid={`marketplace-offer-description-loading-${isLoading}`}
          >
            {description}
          </Description>
          {(isLoading || offer) &&
            (offer?.values || new Array(6).fill({})).map((value, index) => (
              <MetricCard
                key={index}
                title={titles[index]}
                isLoading={isLoading}
                value={value}
              />
            ))}
          {siteId === undefined && (
            <Row
              $padding={"0"}
              $margin={bottomRowMargin}
              $justifyContent={"start"}
              $alignItems={"center"}
              style={{ gridColumn: "span 3" }}
            >
              <MarketplaceButton
                fontWeight="400"
                fontSize="12px"
                label={"Add a site"}
                to={addSiteUrl}
                onClick={notifyAddSiteFn}
              />
            </Row>
          )}
          {siteId !== undefined && (
            <Row
              $padding={"0"}
              $margin={bottomRowMargin}
              $justifyContent={"end"}
              $alignItems={"center"}
              style={{ gridColumn: "span 3" }}
            >
              {!acceptedOffer && offer && demandChartData && (
                <>
                  <MarketplaceButton
                    fontWeight="400"
                    fontSize="12px"
                    label="View matched energy shape"
                    $width="200px"
                    onClick={onViewEnergyShape}
                    background="#fff"
                    borderColor="2px #5486ad"
                    fontColor="#5486ad"
                    data-testid="marketplace-view-energy-shape-button"
                  />
                  {showModal ? (
                    <Modal>
                      <ModalView height="500px" width="900px">
                        <YearDayEnergyChart
                          header="Matched energy"
                          chartData={demandChartData}
                          barColors={["#2779A7"]}
                          legendKeys={{ Import: "From InRange network" }}
                          energyProcuredChartPeriod={energyProcuredChartPeriod}
                          setEnergyProcuredChartPeriod={
                            setEnergyProcuredChartPeriod
                          }
                          height={"360px"}
                          chartHeight={"360px"}
                          $padding={"0"}
                          tooltipFontSize={"12px"}
                          isGeneratingSite={false}
                          isBatterySite={false}
                        />
                        <RowNoDefaults $justifyContent={"end"}>
                          <ShapeDescription>
                            Energy shape is forecasted based on on-site
                            generation and demand. Daily views show the expected
                            shape during high generation (July 2nd) and low
                            generation (Feb 1st) times of year.
                          </ShapeDescription>
                          <MarketplaceButton
                            fontWeight="400"
                            fontSize="12px"
                            label="Close"
                            onClick={() => setShowModal(false)}
                            data-testid="marketplace-close-energy-shape-button"
                          />
                        </RowNoDefaults>
                      </ModalView>
                    </Modal>
                  ) : null}
                </>
              )}
              {!acceptedOffer && (
                <MarketplaceButton
                  fontWeight="400"
                  fontSize="12px"
                  fontColor={isLoading ? "#000" : undefined}
                  label={buttonLabel}
                  disabled={isLoading}
                  onClick={() => {
                    setAcceptedOffer(true);
                    acceptOfferFn();
                  }}
                  data-testid="marketplace-accept-offer-button"
                />
              )}
              {acceptedOffer && (
                <>
                  <Confirmation>{confirmationText}</Confirmation>
                  <MarketplaceButton
                    fontWeight="400"
                    fontSize="12px"
                    fontColor={"#000"}
                    label={"Your request was sent"}
                    disabled={true}
                  />
                </>
              )}
            </Row>
          )}
        </Grid>
      </OfferPanel>
    </>
  );
};

const OfferPanel = styled.div`
  padding: 20px;
  background: #fff;
`;

const Header = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
`;

const AddSiteLinkText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey700};

  a {
    text-decoration: none;
    color: #5486ad;
  }
`;

const Description = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

const ShapeDescription = styled.div`
  font-size: 14px;
  margin: 0px 10px 0px 20px;
`;

const Confirmation = styled.div`
  font-size: 12px;
`;

const MarketplaceButton = styled(SimpleButton)<{ $width?: string }>`
  width: ${(props) => props.$width || "160px"};
  flex-shrink: 0;
  ${({ disabled }) =>
    disabled ? `border: 1px solid ${theme.colors.grey800}` : ""};
  ${({ disabled }) => (disabled ? `background: ${theme.colors.grey250}` : "")};
  opacity: 1;
`;

export default MarketplaceOffer;
