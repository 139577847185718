import {
  Site,
  SiteFile,
} from "@inrange/building-manager-api-client/models-site";
import {
  NoPaddedRow,
  Row,
  TableListSortFilters,
  TableListToggleButtons,
} from "@inrange/theme-components";
import {
  DownloadFile,
  FileListHeader,
  FileListRow,
  FileListTable,
  TableCellData,
} from "@inrange/theme-components/table";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../auth/UserContext";
import {
  defaultSortAscendingValues,
  filterFilesByText,
  sortByColumn,
  toggleSortAscending,
} from "./FileListSortMethods";

interface FileListProps {
  orgId: string;
  site: Site;
  startingSortColumn: string;
}

const filterFilesByFileType = (
  files: SiteFile[] | undefined,
  isBilling: boolean
): SiteFile[] => {
  if (files === undefined) {
    return [];
  }

  const billingFileType = ["invoice", "statement"];
  return files.filter((file) => {
    return isBilling
      ? billingFileType.includes(file.fileType)
      : !billingFileType.includes(file.fileType);
  });
};

const FileList: React.FC<FileListProps> = ({
  orgId,
  site,
  startingSortColumn,
}) => {
  const { user } = useContext(UserContext);
  const [textFilter, setTextFilter] = useState<string>("");
  const [isBilling, setIsBilling] = useState<boolean>(true);
  const [filteredFiles, setFilteredFiles] = useState<SiteFile[]>(
    filterFilesByFileType(site.siteFiles, isBilling)
  );
  const [sortColumn, setSortColumn] = useState<string>(startingSortColumn);
  const [sortAscending, setSortAscending] = useState<boolean>(false);

  const toggleIsBilling = (newIsBilling: boolean) => {
    setSortColumn(newIsBilling ? "issueDate" : "date");
    setIsBilling(newIsBilling);
  };

  const fileTypeSelectionTabs = {
    tab1: {
      label: "Invoices & statements",
      active: isBilling,
      onClick: () => {
        toggleIsBilling(true);
      },
    },
    tab2: undefined,
    tab3: {
      label: "Readings & more",
      active: !isBilling,
      onClick: () => {
        toggleIsBilling(false);
      },
    },
  };

  // Sort and filter
  useEffect(() => {
    if (site.siteFiles === undefined) {
      return;
    }
    let listedSites = filterFilesByFileType(site.siteFiles, isBilling);
    if (textFilter.length > 0) {
      listedSites = filterFilesByText(listedSites, textFilter);
    }
    sortByColumn(listedSites, sortColumn, sortAscending);
    setFilteredFiles(listedSites);
  }, [textFilter, site.siteFiles, sortColumn, sortAscending, isBilling]);

  const handleColumnClick = (columnKey: string) => {
    if (sortColumn === columnKey) {
      toggleSortAscending(setSortAscending);
      return;
    }
    setSortColumn(columnKey);
    setSortAscending(defaultSortAscendingValues[columnKey]);
  };

  const tableHead = (
    <FileListHeader
      isBilling={isBilling}
      sortColumn={sortColumn}
      sortAscending={sortAscending}
      handleColumnClick={handleColumnClick}
    />
  );

  const tableRows = filteredFiles.length ? (
    filteredFiles.map((file) => {
      return (
        <FileListRow
          orgId={orgId}
          site={site}
          userEmail={user!.email}
          file={file}
          key={file.fileGuid}
          isBilling={isBilling}
        />
      );
    })
  ) : (
    <EmptyFileRow isBilling={isBilling} />
  );

  return (
    <>
      <Row $margin="8px" $padding="0">
        <TableListToggleButtons
          tabs={fileTypeSelectionTabs}
          paddingLeft={"2px"}
        />
        <TableListSortFilters
          textFilter={textFilter}
          setTextFilter={setTextFilter}
          paddingRight={"2px"}
        />
      </Row>
      <NoPaddedRow>
        <FileListTable head={tableHead} rows={tableRows} />
      </NoPaddedRow>
    </>
  );
};

const EmptyFileRow = ({ isBilling }: { isBilling: boolean }) => {
  const emptyDescription = isBilling
    ? "When your site has invoices and statements associated with it, you’ll be able to access them here."
    : "When your site has readings and other files associated with it, you’ll be able to access them here.";

  return (
    <tr>
      <td colSpan={isBilling ? 5 : 2} style={{ width: "70%" }}>
        <TableCellData>{emptyDescription}</TableCellData>
      </td>
      <td align="right">
        <DownloadFile downloadDisabled={true} />
      </td>
    </tr>
  );
};

export default FileList;
