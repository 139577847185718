import {
  MonthlyEnergyFlowActuals,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import { Column, Grid, SummaryBoxMetrics } from "@inrange/theme-components";
import { OperationalSiteChart } from "@inrange/theme-components/charts";
import {
  formatCurrency,
  formatUnits,
  tariffToFractionalCurrency,
} from "@inrange/theme-components/formatting";
import React from "react";
import { getLatestActualsMonthIndex } from "../../views/sitelist-utils";
import {
  getAmountOfEnergyProcuredChartData,
  getCostOfEnergyProcuredChartData,
  getOperationalEnergySoldChartData,
  getOperationalRevenueChartData,
} from "../dashboard/chartDataFormatting";

const OperationalSiteSummary: React.FC<{ site: Site }> = ({ site }) => {
  const params = new URL(document.location.toString()).searchParams;
  const testMonthToday = Number(params.get("testMonthToday"));

  const costOfEnergyProcuredChartData = getCostOfEnergyProcuredChartData(site);
  const amountOfEnergyProcuredChartData =
    getAmountOfEnergyProcuredChartData(site);
  const revenueChartData = getOperationalRevenueChartData(site);
  const energySoldChartData = getOperationalEnergySoldChartData(site);

  const lastMonthIndex = getLatestActualsMonthIndex(site, testMonthToday);
  const lastMonthEnergyFlowActuals =
    site.energyFlowMonthlyActuals![lastMonthIndex!];
  const procuredMetrics = generateProcuredMetrics(
    site,
    lastMonthEnergyFlowActuals
  );
  const revenueMetrics = generateRevenueMetrics(
    site,
    lastMonthEnergyFlowActuals
  );
  const tariffMetrics = [
    {
      value: tariffToFractionalCurrency(site.tenantTariff, site.currencyCode),
      valueSubText: "On-site",
      dataTestId: "tenant-tariff",
    },
    {
      value: tariffToFractionalCurrency(
        site.networkImportTariff,
        site.currencyCode
      ),
      valueSubText: "InRange network",
    },
    {
      value: tariffToFractionalCurrency(
        site.inrangeExportTariff,
        site.currencyCode
      ),
      valueSubText: "Avg export",
    },
  ];
  const tariffHeader = "Tariffs";

  return (
    <Grid $cols={1} $colwidth={"370px 1fr"} $gap={"10px"} $margin={"5px"}>
      <Column $height={"675px"}>
        <SummaryBoxMetrics
          metrics={tariffMetrics}
          header={tariffHeader}
          grid={true}
        />
        <SummaryBoxMetrics
          metrics={procuredMetrics}
          header={"Procured previous month"}
          grid={false}
        />
        <SummaryBoxMetrics
          metrics={revenueMetrics}
          header={"Sold previous month"}
          grid={false}
        />
      </Column>
      <Column $height={"1600px"}>
        <OperationalSiteChart
          header="Cost of energy procured"
          chartData={costOfEnergyProcuredChartData}
          toolTipText="The cost of energy procured from InRange during a given month, where energy can be procured from the on-site installation or from the InRange network, at the relevant tariffs. For months where no data exists, the data reflects the forecasted cost. Does not include VAT."
          currency={true}
          currencyCode={site.currencyCode}
          exported={false}
          height={"25%"}
        />
        <OperationalSiteChart
          header="Amount of energy procured"
          chartData={amountOfEnergyProcuredChartData}
          toolTipText="The amount of energy in kWh procured from InRange during a given month, where energy can be procured from the on-site installation or from the InRange network. For months where no data exists, the data reflects the forecasted amount."
          currency={false}
          exported={false}
          height={"25%"}
        />
        <OperationalSiteChart
          header="Revenue"
          chartData={revenueChartData}
          toolTipText="The revenue generated from energy sold after the given revenue share during a given month, where energy be sold on-site or exported to the InRange network, at the relevant tariffs. For months where no data exists, the data reflects the forecasted revenue. Does not include VAT."
          currency={true}
          currencyCode={site.currencyCode}
          exported={true}
          height={"25%"}
        />
        <OperationalSiteChart
          header="Energy sold"
          chartData={energySoldChartData}
          toolTipText="The amount of energy sold in kWh during a given month, where energy can be sold on-site or exported to the InRange network. For months where no data exists, the data reflects the forecasted amount."
          currency={false}
          exported={true}
          height={"25%"}
        />
      </Column>
    </Grid>
  );
};

export default OperationalSiteSummary;

const generateProcuredMetrics = (
  site: Site,
  lastMonthEnergyFlowActuals: MonthlyEnergyFlowActuals | undefined
) => {
  const procurementTariff = site.networkImportTariff;
  const behindTheMeterTariff = site.tenantTariff;
  const lastMonthBehindMeter =
    lastMonthEnergyFlowActuals?.energyFlow?.behindMeter ?? 0;
  const lastMonthNetworkImport =
    lastMonthEnergyFlowActuals?.energyFlow?.networkImport ?? 0;
  const lastMonthCostBehindMeter = lastMonthBehindMeter * behindTheMeterTariff;
  const lastMonthCostNetworkImport = lastMonthNetworkImport * procurementTariff;
  const metrics = [
    {
      value: `${formatCurrency(lastMonthCostBehindMeter, site.currencyCode, 2)} (${formatUnits(lastMonthBehindMeter, "kWh", 2)})`,
      valueSubText: "From on-site generation",
      dataTestId: "procured-behind-meter-monthly",
    },
    {
      value: `${formatCurrency(lastMonthCostNetworkImport, site.currencyCode, 2)} (${formatUnits(lastMonthNetworkImport, "kWh", 2)})`,
      valueSubText: "Imported from network\nexcl. non-commodity costs",
      dataTestId: "procured-network-import-monthly",
    },
  ];
  return metrics;
};

const generateRevenueMetrics = (
  site: Site,
  lastMonthEnergyFlowActuals: MonthlyEnergyFlowActuals | undefined
) => {
  const tenantTariff = site.tenantTariff;
  const exportTariff = site.inrangeExportTariff;
  const leaseRevenueShare = site.landlordLeaseRevenueShare;
  const lastMonthExport = lastMonthEnergyFlowActuals?.energyFlow?.exported ?? 0;
  const lastMonthRevenueExport =
    lastMonthExport * exportTariff * leaseRevenueShare;
  const lastMonthBehindMeter =
    lastMonthEnergyFlowActuals?.energyFlow?.behindMeter ?? 0;
  const lastMonthRevenueBehindMeter =
    lastMonthBehindMeter * tenantTariff * leaseRevenueShare;
  const metrics = [
    {
      value: `${formatCurrency(lastMonthRevenueBehindMeter, site.currencyCode, 2)} (${formatUnits(lastMonthBehindMeter, "kWh", 2)})`,
      valueSubText: "On-site behind the meter",
      dataTestId: "revenue-behind-meter-monthly",
    },
    {
      value: `${formatCurrency(lastMonthRevenueExport, site.currencyCode, 2)} (${formatUnits(lastMonthExport, "kWh", 2)})`,
      valueSubText: "Exported to network",
      dataTestId: "revenue-export-monthly",
    },
  ];

  return metrics;
};
