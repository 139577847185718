import {
  useNetworkSiteList,
  useOrganisation,
  useOrgListUsers,
} from "@inrange/building-manager-api-client";
import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import { NetworkSite } from "@inrange/building-manager-api-client/models-site";
import {
  ErrorSidebar,
  Loaded,
  Loading,
  PortfolioHeader,
  SidebarWithHeader,
} from "@inrange/theme-components";
import { useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UserContext } from "../../auth/UserContext";
import AddSite from "./AddSite";
import { countSitesByOwnershipAndOperationalStatus } from "./aggregationCalculations";
import LandlordSiteList from "./LandlordSiteList";
import Marketplace from "./Marketplace";
import TenantSiteList from "./TenantSiteList";

const Dashboard: React.FC = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const location = useLocation();
  const { user, logout, onOrgSwitch } = useContext(UserContext);
  const [addSiteCrumb, setAddSiteCrumb] = useState("Add a site");

  const kindeOrgCode = user!.kinde_org_code;
  const kindeOrgs = user!.kinde_orgs;

  const currentUrl = location.pathname;
  const childPath = "/" + location.pathname.split("/")[3];
  const rootPath = `/org/${orgId}/`;
  const isAddSite = childPath === "/add-site";

  const { fetchOrganisation, useOrganisationSites } = useOrganisation(
    orgId,
    orgId
  );
  const organisation = fetchOrganisation.data?.organisation;
  const organisationSiteListSites = fetchOrganisation.data?.sites;

  const { addOrganisationUser } = useOrgListUsers(
    [organisation?.id],
    undefined,
    orgId
  );

  // Always fetch the network sites data on any dashboard page
  // This means it's more likely to be loaded when a user clicks
  // to the marketplace tab.
  const { networkSites, fetchNetworkSiteList } = useNetworkSiteList(
    orgId,
    // Only trigger fetch once we've loaded the user's org data as this is more important
    fetchOrganisation.isSuccess,
    orgId
  );

  // Trigger fetching of all sites for the organisation eagerly so that they are more
  // likely to be pre-loaded when the user navigates to any of them
  useOrganisationSites(organisation?.siteOwnerships);

  if (fetchOrganisation.isError) {
    return (
      <ErrorSidebar onClickLogout={logout}>
        <Loaded>Organisation not found</Loaded>
      </ErrorSidebar>
    );
  }
  if (
    fetchOrganisation.isLoading ||
    !organisation ||
    !organisationSiteListSites
  ) {
    return <Loading label={"Loading your dashboard data..."} />;
  }

  // Show loading indicator if any network sites are still loading and this is the marketplace tab
  const anyNetworkSitesLoading =
    fetchNetworkSiteList.isLoading ||
    networkSites.some((query) => query.isLoading);
  const isMarketplaceUrl = childPath === "/marketplace";
  if (isMarketplaceUrl && anyNetworkSitesLoading) {
    return <Loading label="Loading marketplace site data..." />;
  }

  const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;
  const successfulNetworkSites: NetworkSite[] = testMode
    ? []
    : networkSites
        .filter((query) => query.isSuccess)
        .map((query) => query.data?.sites || [])
        .flat();

  const orgName = organisation?.name;
  document.title = orgName ? `InRange - ${orgName}` : document.title;

  const orgType = organisation.orgType;

  const siteCounts = countSitesByOwnershipAndOperationalStatus(
    organisationSiteListSites,
    organisation.siteOwnerships
  );

  const crumbs = isAddSite
    ? [`${organisation.name}`, addSiteCrumb, "Add site"]
    : [`${orgType} Dashboard`, `${organisation.name} Portfolio`];

  async function handleAddUser(email: string): Promise<void> {
    if (!email) return;

    await addOrganisationUser.mutateAsync(
      {
        email: email,
        orgId: organisation!.id,
      },
      {
        onSuccess: () => {
          trackUserInteraction(
            {
              invitee_organisation_email: email,
              invitee_organisation_id: organisation!.id,
              inviter_organisation_email: user!.email,
              inviter_organisation_id: organisation!.id,
              type: "withinOrg",
            },
            "BASIC_ACTION",
            "INVITE_CREATED",
            user!.email.toLocaleLowerCase(),
            "customer-app"
          );
        },
      }
    );
  }

  const shareAttributes = {
    currentOrgUserEmails: organisation.userEmails,
    userEmailsIsLoading: addOrganisationUser.isLoading,
    addUser: handleAddUser,
    onShareButtonClick: () => {
      trackUserInteraction(
        {
          type: "siteList",
        },
        "BASIC_ACTION",
        "SHARE_SITE_CLICK",
        user!.email.toLowerCase(),
        "customer-app"
      );
    },
  };

  return (
    <SidebarWithHeader
      crumbs={crumbs}
      currentUrl={currentUrl}
      userEmail={user!.email}
      rootPath={rootPath}
      orgType={orgType}
      siteCounts={siteCounts}
      onClickLogout={logout!}
      onOrgSwitch={onOrgSwitch!}
      userOrgs={kindeOrgs}
      currentKindeOrg={kindeOrgCode}
    >
      {childPath !== "/add-site" ? (
        <PortfolioHeader
          siteCounts={siteCounts}
          currentUrl={currentUrl}
          rootPath={rootPath}
          orgType={orgType}
          notifyAddSiteFn={() => {
            trackUserInteraction(
              {
                from_page: "site list",
              },
              "ADD_SITE",
              "ADD_SITE_ENTERED",
              user!.email.toLowerCase(),
              "customer-app"
            );
          }}
          shareAttributes={shareAttributes}
        />
      ) : null}
      <DashboardTabContent
        organisation={organisation}
        organisationSiteListSites={organisationSiteListSites}
        successfulNetworkSites={successfulNetworkSites}
        siteCounts={siteCounts}
        currentUrl={childPath}
        rootPath={rootPath}
        orgType={orgType}
        setAddSiteCrumb={setAddSiteCrumb}
      />
    </SidebarWithHeader>
  );
};

export default Dashboard;

interface DashboardTabContentProps {
  organisation: Organisation;
  organisationSiteListSites: OrgSiteListEntry[];
  successfulNetworkSites: NetworkSite[];
  siteCounts: Record<string, number>;
  currentUrl: string;
  rootPath: string;
  orgType: string;
  setAddSiteCrumb: (crumb: string) => void;
}

const DashboardTabContent: React.FC<DashboardTabContentProps> = ({
  organisation,
  organisationSiteListSites,
  successfulNetworkSites,
  siteCounts,
  currentUrl,
  rootPath,
  orgType,
  setAddSiteCrumb,
}) => {
  const landlordCount = siteCounts.landlord || 0;
  const ownerOccCount = siteCounts.ownerOccupier || 0;
  const isLeaseMetrics = ownerOccCount > landlordCount;

  const contents: Record<string, Record<string, JSX.Element>> = {
    tenant: {
      "/dashboard": (
        <TenantSiteList
          sites={organisationSiteListSites}
          organisation={organisation}
          isLeaseMetrics={isLeaseMetrics}
        />
        // This is the same as /sites. It used to be a unique page, but now it's the same
        // We should deprecate this once we stop using it in the URL
      ),
      "/sites": (
        <TenantSiteList
          sites={organisationSiteListSites}
          organisation={organisation}
          isLeaseMetrics={isLeaseMetrics}
        />
      ),
      "/marketplace": (
        <Marketplace
          sites={organisationSiteListSites}
          networkSites={successfulNetworkSites}
          rootPath={rootPath}
        />
      ),
      "/add-site": (
        <AddSite
          organisation={organisation}
          setAddSiteCrumb={setAddSiteCrumb}
        />
      ),
    },
    landlord: {
      "/dashboard": (
        <LandlordSiteList
          sites={organisationSiteListSites}
          organisation={organisation}
          isLeaseMetrics={isLeaseMetrics}
        />
      ),
      "/sites": (
        <LandlordSiteList
          sites={organisationSiteListSites}
          organisation={organisation}
          isLeaseMetrics={isLeaseMetrics}
        />
      ),
      "/marketplace": (
        <Marketplace
          sites={organisationSiteListSites}
          networkSites={successfulNetworkSites}
          rootPath={rootPath}
        />
      ),
      "/add-site": (
        <AddSite
          organisation={organisation}
          setAddSiteCrumb={setAddSiteCrumb}
        />
      ),
    },
  };
  return contents[orgType.toLowerCase()][currentUrl];
};
