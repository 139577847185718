import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import { Site } from "@inrange/building-manager-api-client/models-site";

const calcIsOwnerOccupied = (
  site?: Site | OrgSiteListEntry,
  organisation?: Organisation
): boolean | undefined => {
  if (!site || !organisation) {
    return undefined;
  }
  const ownership = site.siteOwnerships.find(
    (siteOwnership) => siteOwnership.orgID === organisation.id
  )?.ownership;
  const isOwnerOccupier = ownership === "ownerOccupier";
  return isOwnerOccupier;
};

export default calcIsOwnerOccupied;
