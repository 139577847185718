import { BuyOfferEnergyFlows } from "@inrange/building-manager-api-client/models-site";
import { ChartData } from "../charts/chart-types";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const genEnergyDemandChartData = (
  buyOfferEnergyFlows: BuyOfferEnergyFlows,
  period: string
): ChartData[] => {
  if (period === "year")
    return genEnergyDemandChartDataYear(buyOfferEnergyFlows);
  return genEnergyDemandChartDataDay(buyOfferEnergyFlows, period);
};

const genEnergyDemandChartDataDay = (
  buyOfferEnergyFlows: BuyOfferEnergyFlows,
  period: string
): ChartData[] => {
  const chartData: ChartData[] = [];

  const importDay =
    period === "summer"
      ? buyOfferEnergyFlows.summerDay
      : buyOfferEnergyFlows.winterDay;

  if (!importDay) return chartData;

  let hour = 0;
  let minute = 0;
  for (const importKwh of importDay) {
    const hourText = hour.toString().padStart(2, "0");
    const minuteText = minute.toString().padStart(2, "0");
    chartData.push({
      index: `${hourText}:${minuteText}`,
      Import: importKwh,
    });
    if (minute === 30) {
      minute = 0;
      hour += 1;
    } else {
      minute += 30;
    }
  }

  return chartData;
};

const genEnergyDemandChartDataYear = (
  buyOfferEnergyFlows: BuyOfferEnergyFlows
): ChartData[] => {
  const oneMonthChartData = (monthIx: number): ChartData => {
    return {
      index: months[monthIx - 1],
      Import: buyOfferEnergyFlows.monthly[monthIx],
    };
  };

  const result: ChartData[] = [];
  for (let i = 1; i <= 12; i++) {
    result.push(oneMonthChartData(i));
  }
  return result;
};
