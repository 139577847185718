import React from "react";
import { formatKiloUnitsWithValue } from "../../formatting/formatKiloUnits";
import TableCellData from "./TableCellData";

import { OrgSiteListEntry } from "@inrange/building-manager-api-client/models-organisation";
import available from "../../images/icons/available.svg";
import comingSoon from "../../images/icons/comingSoon.svg";
import unavailable from "../../images/icons/unavailable.svg";

interface GenerationHeadroomCellProps {
  site: OrgSiteListEntry;
}

interface GenerationHeadroomCellViewProps {
  success: boolean;
  effectiveExportLimit: number;
  hasSiteExportLimit: boolean;
}

const GenerationHeadroomCell: React.FC<GenerationHeadroomCellProps> = ({
  site,
}) => {
  const { success, generationHeadroomKva } = site.substationData;
  const siteExportLimit = site.exportLimit;
  const effectiveExportLimit =
    siteExportLimit !== null ? siteExportLimit : generationHeadroomKva;
  return (
    <GenerationHeadroomCellView
      success={success}
      effectiveExportLimit={effectiveExportLimit || 0}
      hasSiteExportLimit={siteExportLimit !== null}
    />
  );
};

const GenerationHeadroomCellView: React.FC<GenerationHeadroomCellViewProps> = ({
  success,
  effectiveExportLimit,
  hasSiteExportLimit,
}) => {
  if (!success && !hasSiteExportLimit) {
    return (
      <TableCellData>
        <img src={comingSoon} alt={"Coming Soon"} />
      </TableCellData>
    );
  }

  let networkAvailabilityIcon = unavailable;
  let networkAvailabilityAlt = "Unavailable";
  if (effectiveExportLimit > 0) {
    networkAvailabilityIcon = available;
    networkAvailabilityAlt = "Available";
  }

  return (
    <>
      <TableCellData>
        <img src={networkAvailabilityIcon} alt={networkAvailabilityAlt} />
        <br />
        {formatKiloUnitsWithValue(effectiveExportLimit, "VA")}
      </TableCellData>
    </>
  );
};

export default GenerationHeadroomCell;
