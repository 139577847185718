const getAdminAppDomain = (): string => {
  const customerDomain: string = document.location.hostname;
  return customerDomain === "localhost"
    ? "http://localhost:3000"
    : "https://" +
        (customerDomain.endsWith(".inrange.dev")
          ? customerDomain.replace(/^customer-/, "admin-")
          : "admin.inrange.io");
};

export default getAdminAppDomain;
