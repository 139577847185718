import React from "react";
import { fractionalCurrencySymbol } from "../../formatting/currency-utils";
import { formatCurrencyToNearestTen } from "../../formatting/formatCurrency";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import formatMass from "../../formatting/formatMass";
import tariffToFractionalCurrency from "../../formatting/tariffToFractionalCurrency";
import { MetricCardsRow, SiteMetricCard } from "./index";

interface Metrics {
  totalSites: number;
  totalAnnualDemand: number;
  averageTariff: number;
  totalEnergyProcuredCost: number;
  totalSavings: number;
  averageMarketTariff: number;
  emissionsAvoided: number;
}

interface TenantSiteListMetricCardsProps {
  metrics: Metrics;
  summaryCurrencyCode: string;
}

export const TenantSiteListMetricCards: React.FC<
  TenantSiteListMetricCardsProps
> = ({ metrics, summaryCurrencyCode }) => {
  return (
    <MetricCardsRow>
      <SiteMetricCard
        title="Total sites"
        value={metrics.totalSites}
        color="grey"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        dataTestId="total-sites"
      />
      <SiteMetricCard
        title="Total annual demand"
        value={formatUnitsToNearestTen(metrics.totalAnnualDemand, "kWh")}
        tooltipText={`The total estimated energy demand across all sites.`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        dataTestId="total-annual-demand"
      />
      <SiteMetricCard
        title="Average tariff for solar energy"
        value={tariffToFractionalCurrency(
          metrics.averageTariff,
          summaryCurrencyCode
        )}
        tooltipText="The average tariff for procuring solar energy from InRange across your sites."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        dataTestId="average-tariff"
      />
      <SiteMetricCard
        title="Total procured from InRange in year 1"
        value={formatCurrencyToNearestTen(
          metrics.totalEnergyProcuredCost,
          summaryCurrencyCode,
          0,
          false
        )}
        note="From on-site generation and the InRange network"
        tooltipText="The total amount for all energy procured across sites, given site-specific tariffs and energy demand. Includes energy procured behind the meter from an on-site installation, as well as energy imported from the InRange network."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        dataTestId="total-energy-procured"
      />
      <SiteMetricCard
        title="Total savings in year 1"
        value={formatCurrencyToNearestTen(
          metrics.totalSavings,
          summaryCurrencyCode,
          0,
          false
        )}
        note={`Based on market tariff of ${(metrics.averageMarketTariff * 100).toFixed(2)} ${fractionalCurrencySymbol(summaryCurrencyCode)}/kWh`}
        tooltipText="The total annual savings you’ll enjoy from procuring energy from InRange. Calculated as the difference between the current market tariff (as stated) and a per site renewable energy tariff and demand."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        dataTestId="total-savings"
      />
      <SiteMetricCard
        title="Total emissions avoided in year 1"
        value={formatMass(metrics.emissionsAvoided) + " CO2e"}
        tooltipText="The total emissions you will have avoided by consuming solar energy across sites. The CO2e multiplier per MWh is based on the annual carbon intensity of the grid at each site location."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        dataTestId="total-emissions-avoided"
      />
    </MetricCardsRow>
  );
};
