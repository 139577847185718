import {
  EnergyDemandSidebar,
  EnergySupplySidebar,
  Row,
  Tooltip,
} from "@inrange/theme-components";
import {
  formatCurrencyToNearestTen,
  fractionalCurrencySymbol,
} from "@inrange/theme-components/formatting";
import React from "react";
import styled from "styled-components";

import { Site } from "@inrange/building-manager-api-client/models-site";
import {
  PeakOffPeakChart,
  YearDayEnergyChart,
} from "@inrange/theme-components/charts";
import { useState } from "react";
import {
  genEnergyProcuredChartData,
  genPeakOffPeakChartDataYear,
} from "../dashboard/chartDataFormatting";

interface EnergySavingsProps {
  site: Site;
  isOwnerOccupier: boolean;
  isGeneratingSite: boolean;
}

const EnergySavings: React.FC<EnergySavingsProps> = ({
  site,
  isOwnerOccupier,
  isGeneratingSite,
}) => {
  const [energyProcuredChartPeriod, setEnergyProcuredChartPeriod] = useState<
    "year" | "summer" | "winter"
  >("year");

  const isBatterySite = !!site.batteryCapacity && !!site.batteryPower;

  let annualSavings = site.financialModels.tenant.savings;
  let lifetimeSavings = site.financialModels.tenant.lifetimeSavings;
  let displayTariff = (site.tenantTariff * 100).toFixed(2);

  if (isOwnerOccupier) {
    annualSavings =
      site.financialModels.ownerOccupier[site.activeInvestmentModel].savings;
    lifetimeSavings =
      site.financialModels.ownerOccupier[site.activeInvestmentModel]
        .lifetimeSavings;
    if (site.activeInvestmentModel === "license") {
      displayTariff = "0";
    }
  }

  const energyProcuredChartData = genEnergyProcuredChartData(
    site,
    energyProcuredChartPeriod
  );
  const peakOffPeakChartData = genPeakOffPeakChartDataYear(site);

  const peakInRangePerc =
    (site.energyFlowAnnual.peakBehindMeter +
      site.energyFlowAnnual.peakInRange) /
    site.energyFlowAnnual.demand;
  const peakGridPerc =
    site.energyFlowAnnual.peakGrid / site.energyFlowAnnual.demand;
  const offPeakInRangePerc =
    (site.energyFlowAnnual.offPeakBehindMeter +
      site.energyFlowAnnual.offPeakInRange) /
    site.energyFlowAnnual.demand;
  const offPeakGridPerc =
    site.energyFlowAnnual.offPeakGrid / site.energyFlowAnnual.demand;

  const marketTariff = (site.marketTariff * 100).toFixed(2);
  return (
    <>
      <Row>
        <SavingsMetric>
          <MetricTooltip>
            <h3>Savings from InRange</h3>
            <Tooltip
              text={
                "The total saved from the PV system and InRange network is the forecasted energy demand over 25 years, multiplied by the difference between the current market tariff and the InRange tariff."
              }
            />
          </MetricTooltip>
          <h5>
            Projected savings from the PV system and InRange network for this
            site given a starting market tariff of {marketTariff}{" "}
            {fractionalCurrencySymbol(site.currencyCode)}/kWh.
          </h5>
          <MetricRow>
            <Metric>
              <h4>
                {formatCurrencyToNearestTen(
                  lifetimeSavings,
                  site.currencyCode,
                  0,
                  false
                )}
              </h4>
              <h5>Total saved over 25 years</h5>
            </Metric>
            <Metric>
              <h4>
                {formatCurrencyToNearestTen(
                  annualSavings,
                  site.currencyCode,
                  0,
                  false
                )}
              </h4>
              <h5>Saved in year 1</h5>
            </Metric>
          </MetricRow>
        </SavingsMetric>
        <TariffMetric>
          <MetricTooltip>
            <h3>InRange tariff</h3>
            <Tooltip
              text={
                "InRange offers fixed energy tariffs for a specified number of years. This tariff is based on the site profile, including the energy generation potential and the energy demand."
              }
            />
          </MetricTooltip>

          <h5>Cost of energy procured from InRange</h5>
          <MetricRow>
            {isGeneratingSite && (
              <Metric>
                <h4>
                  {displayTariff +
                    fractionalCurrencySymbol(site.currencyCode) +
                    "/kWh"}
                </h4>
                <h5>On-site</h5>
              </Metric>
            )}
            <Metric>
              <h4>
                {(site.networkImportTariff * 100.0).toFixed(2) +
                  fractionalCurrencySymbol(site.currencyCode) +
                  "/kWh"}
              </h4>
              <h5>InRange network</h5>
              <h5>excl. non-commodity costs</h5>
            </Metric>
          </MetricRow>
        </TariffMetric>
      </Row>
      <Row>
        <YearDayEnergyChart
          header={"Amount of energy procured"}
          chartData={energyProcuredChartData}
          isGeneratingSite={isGeneratingSite}
          isBatterySite={isBatterySite}
          energyProcuredChartPeriod={energyProcuredChartPeriod}
          setEnergyProcuredChartPeriod={setEnergyProcuredChartPeriod}
          height="500px"
        />

        <EnergyDemandSidebar
          behindMeter={site.energyFlowAnnual.behindMeter}
          inrangeNetwork={site.energyFlowAnnual.networkImport}
          grid={site.energyFlowAnnual.gridImport}
          isGeneratingSite={isGeneratingSite}
          isBatterySite={isBatterySite}
        />
      </Row>
      <Row>
        <PeakOffPeakChart
          chartData={peakOffPeakChartData}
          totalOnSiteGeneration={site.energyFlowAnnual.generation}
          isGeneratingSite={isGeneratingSite}
          height="730px"
        />

        <EnergySupplySidebar
          peakInRange={peakInRangePerc}
          peakGrid={peakGridPerc}
          offPeakInRange={offPeakInRangePerc}
          offPeakGrid={offPeakGridPerc}
        />
      </Row>
    </>
  );
};

export default EnergySavings;

const MetricTooltip = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SavingsMetric = styled.div`
  padding: 16px 16px;
  margin: 16px 0px 16px 0px;
  border-radius: 8px;
  flex-grow: 1;
  background: #eee;
  height: 164px;

  &:last-child {
    width: 470px;
    margin-left: 16px;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    padding: 0;
    margin: 0 0 8px;
  }
  h4 {
    font-size: 25px;
    font-weight: 600;
    line-height: 25px;
    padding: 0;
    margin: 8px 0;
  }
  h5 {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    padding: 0;
    margin: 0;
  }
`;

const TariffMetric = styled(SavingsMetric)`
  width: 436px;
  flex-grow: 0;
`;

const Metric = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;
const MetricRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
