import React from "react";
import styled from "styled-components";
import circleQuestionMarkTick from "../../images/icons/circleQuestionMarkTick.svg";
import { Row } from "../layout/Flex";
import Tooltip from "../Tooltip";

export interface TenantSiteMetricValues {
  procurementCostYearOne: number;
  procuredEnergyYearOne: number;
  savingsYearOne: number;
  marketTariff: number;
  totalDemandYearOne: number;
  demandFulfilledByInRange: number;
  emissionsAvoidedYearOne: number;
  cfeScore: number;
  hhDataUploaded: boolean;
}

export interface LandlordSiteMetricValues {
  // Common to both TenantSiteMetricValues and LandlordSiteMetricValues
  procurementCostYearOne: number;
  // Only required for Owner Occupier sites
  savingsYearOne?: number;
  marketTariff: number;
  demandFulfilledByInRange: number;
  emissionsAvoidedYearOne: number;
  cfeScore: number;
  hhDataUploaded: boolean;

  // Landlord specific values
  revenueYearOne: number;
  initialInvestment: number;
  lifetimeRevenue: number;
  totalProjectCost: number;
  irr: number;
  payback: number;
  revenuePerFt2: number;
  onSiteDemandYearOne: number;
  generationPotentialYearOne: number;
  onSiteGenerationConsumed: number;
  investmentModel: string;
}

interface SiteMetricCardProps {
  title: string;
  value: string | number | Array<string | number>;
  note?: string;
  color?: string;
  tooltipText?: string;
  width?: string;
  height?: string;
  dataTestId?: string;
  titleHeight?: string;
  tooltipPosition?: "top" | "right" | "bottom" | "left";
  visible?: boolean;
  titleColor?: string;
  $minWidth?: string;
  $maxWidth?: string;
  $flex?: string;
}

export const SiteMetricCard: React.FC<SiteMetricCardProps> = ({
  title,
  value,
  note,
  color,
  tooltipText,
  width,
  height,
  dataTestId,
  titleHeight,
  tooltipPosition,
  visible = true,
  titleColor,
  $minWidth,
  $maxWidth,
  $flex,
}) => {
  return (
    <MetricCard
      color={color}
      width={width}
      height={height}
      visible={visible}
      $minWidth={$minWidth}
      $maxWidth={$maxWidth}
      $flex={$flex}
    >
      <SiteMetricCardStyle>
        <SiteMetricTitle
          color={color}
          fontColor={titleColor}
          height={titleHeight}
        >
          {title}
        </SiteMetricTitle>
        {tooltipText && (
          <MetricTooltip>
            <Tooltip text={tooltipText} position={tooltipPosition || "right"}>
              <img src={circleQuestionMarkTick} alt="info" />
            </Tooltip>
          </MetricTooltip>
        )}
      </SiteMetricCardStyle>
      <SiteMetricValue data-testid={dataTestId} color={color}>
        {Array.isArray(value)
          ? value.map((v, index) => <div key={index}>{v}</div>)
          : value}
      </SiteMetricValue>
      {note && <SiteMetricNote>{note}</SiteMetricNote>}
    </MetricCard>
  );
};

export const MetricCardsRow = styled(Row)`
  height: 168px;
  margin: 8px 12px;
  padding: 0;
`;

const MetricTooltip = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SiteMetricCardStyle = styled.div`
  display: flex;
  height: 60%;
  padding-top: 8px;
  justify-content: space-between;
`;

interface MetricCardProps {
  color?: string;
  width?: string;
  height?: string;
  visible?: boolean;
  $minWidth?: string;
  $maxWidth?: string;
  $flex?: string;
}

const MetricCard = styled.div<MetricCardProps>`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 8px;
  background: white;
  width: ${(props) => (props.width ? props.width : "230px")};
  height: ${(props) => (props.height ? props.height : "112px")};
  /* the below css gives us a neat animation to help inform the user of the updated
  cards */
  overflow: hidden;
  ${(props) =>
    props.visible
      ? `opacity: 1; transition: opacity 0.5s ease-out;`
      : `width: 0; opacity: 0; padding: 0; margin: -4px;`}
  ${(props) =>
    props.$maxWidth &&
    `
    max-width: ${props.$maxWidth};
  `}
  ${(props) =>
    props.$minWidth &&
    `
    min-width: ${props.$minWidth};
  `}
  ${(props) =>
    props.$flex &&
    `
    flex: ${props.$flex};
  `}
  ${({ color }) => color === "blue" && `background: #00022F`}
  ${({ color }) => color === "grey" && `background: #DFDFDF`}
`;

interface SiteMetricTitleProps {
  color?: string;
  fontColor?: string;
  height?: string;
}

const SiteMetricTitle = styled.p<SiteMetricTitleProps>`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  height: ${(props) => (props.height ? "35px" : null)};
  margin: 0;
  padding: 0;
  color: ${({ color, fontColor }) =>
    fontColor ? fontColor : color === "blue" && "white"};
`;

interface SiteMetricValueProps {
  color?: string;
}

const SiteMetricValue = styled.div<SiteMetricValueProps>`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  padding: 0;
  ${({ color }) => color === "blue" && `color: white`}
`;

const SiteMetricNote = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  text-align: left;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.grey900};
`;
