import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import { Site } from "@inrange/building-manager-api-client/models-site";
import { createContextTS } from "@inrange/shared-components";
import React, { PropsWithChildren, useState } from "react";

interface AppData {
  organisation?: Organisation;
  site?: Site;
}

interface AppDataContextProps {
  appData?: AppData;
  updateAppData: (data: AppData) => void;
}

export const AppDataContext = createContextTS<AppDataContextProps>();

export const AppDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [appData, setAppData] = useState<AppData | undefined>(undefined);

  return (
    <AppDataContext.Provider
      value={{
        appData,
        updateAppData: setAppData,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};
