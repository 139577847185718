import { DualButton } from "@inrange/theme-components/buttons";
import React from "react";

interface LeaseLicenseToggleProps {
  investmentModel: string;
  setInvestmentModel: (model: string) => void;
  showLeaseLicenseToggle: boolean;
}

export const LeaseLicenseToggle: React.FC<LeaseLicenseToggleProps> = ({
  investmentModel,
  setInvestmentModel,
  showLeaseLicenseToggle,
}) => {
  if (!showLeaseLicenseToggle) return null;

  const useLease = () => setInvestmentModel("lease");
  const useLicense = () => setInvestmentModel("license");
  const viewComparison = () => setInvestmentModel("none");

  const isLease = investmentModel === "lease";
  const isLicense = investmentModel === "license";
  const viewingComparison = investmentModel === "none";

  return (
    <DualButton
      button1={{
        label: "License",
        active: isLicense,
        onClick: useLicense,
        testId: "license-button",
      }}
      button2={{
        label: "Lease",
        active: isLease,
        onClick: useLease,
        testId: "lease-button",
      }}
      middleButton={{
        label: "Compare",
        active: viewingComparison,
        onClick: viewComparison,
        testId: "compare-button",
      }}
    />
  );
};
