/*
  formatMass(100) => "100g"
  formatMass(1_000) => "1Kg"
  formatMass(1000_000) => "1t"
  formatMass(1000_000_000) => "1kt"
  formatMass(1_234) => "1.2kg"
*/
const formatMass = (gramValue: number | null | undefined): string => {
  if (gramValue === null || gramValue === undefined) return "0g";

  if (gramValue < 1000) return `${gramValue}g`;

  if (gramValue < 1_000_000) return `${(gramValue / 1000).toFixed(1)}Kg`;

  if (gramValue < 1_000_000_000) {
    return `${(gramValue / 1_000_000).toFixed(1)}t`;
  }

  return `${(gramValue / 1_000_000_000).toFixed(1)}kt`;
};

export default formatMass;
