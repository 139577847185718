import React from "react";
import SimpleButton from "../buttons/SimpleButton";

interface SiteLinkProps {
  siteId: string;
  orgId: string;
}

const SiteLink: React.FC<SiteLinkProps> = ({ siteId, orgId }) => {
  const siteUrl = `/org/${orgId}/site/${siteId}`;
  return (
    <SimpleButton
      fontWeight="400"
      label="View site"
      to={siteUrl}
      data-testid={`site-link-${siteId}`}
    />
  );
};

export default SiteLink;
