import { useOrganisation, useSite } from "@inrange/building-manager-api-client";
import { useContextTS } from "@inrange/shared-components";
import React, { PropsWithChildren, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppDataContext } from "./AppDataContext";

const AppData: React.FC<PropsWithChildren> = ({ children }) => {
  const { orgId, siteId } = useParams<{ orgId: string; siteId: string }>();
  const { updateAppData } = useContextTS(AppDataContext);
  const { fetchOrganisation } = useOrganisation(orgId, orgId);
  const organisation = fetchOrganisation.data?.organisation;

  // Delay fetching site data until organisation is loaded
  const { fetchSite } = useSite({
    siteId: organisation ? siteId : undefined,
    userOrgId: orgId,
    app: "customer",
  });
  const site = fetchSite.data?.site;

  useEffect(() => {
    updateAppData({
      organisation: organisation,
      site: site,
    });
  }, [updateAppData, organisation, site]);

  return <div>{children}</div>;
};

export default AppData;
