import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import {
  Site,
  SiteOwnership,
} from "@inrange/building-manager-api-client/models-site";

const calcSiteOwnershipForOrg = (
  site: Site | OrgSiteListEntry,
  organisation: Organisation
): string => {
  const siteOwnership: string | undefined = site.siteOwnerships.find(
    (so: SiteOwnership) => {
      return so.orgID === organisation.id;
    }
  )?.ownership;

  if (siteOwnership === "ownerOccupier") {
    return siteOwnership;
  } else {
    return organisation.orgType.toLowerCase();
  }
};

export default calcSiteOwnershipForOrg;
